//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext } from '@nuxtjs/composition-api'
import { makeStoreReadonlyProperties } from '@/utils/reactivity'
import { DOCUMENT_STATUS_DRAFT } from '../constants/documents'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    message: String,
  },
  setup(props, { emit }) {
    const modal = useModal()

    const readonlyProperties = makeStoreReadonlyProperties('documents', ['status', 'id'])

    const { $getRepository, $bus, $toast, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const reloadPage = () => {
      setLoading(true, 'reload_page')
      window.location.reload()
    }

    const navigateTo = (target) => {
      window.location.href = target
    }

    return {
      props,
      ...modal,
      ...readonlyProperties,
      reloadPage,
      isLoading,
      hasErrorState,
      navigateTo,
      DOCUMENT_STATUS_DRAFT,
    }
  },
})
