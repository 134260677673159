//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext, ref, useFetch, watch, nextTick } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'
import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { updateUser } = useUser()
    const { $getRepository, $bus, $toast, $axios, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const fetchError = ref(null)
    const invites = ref([])
    const loaded = ref(false)

    const reply = async (accept, invite) => {
      let key = `${accept ? 'accept' : 'refuse'}_${invite.id}`
      setLoading(true, key)
      try {
        const { data } = await $axios.$put(`/api/users/me/invitations/${invite.id}/${accept ? 'accept' : 'refuse'}`, {
          params: {},
          progress: false,
        })
        invite.status = accept ? 'accepted' : 'refused'
      } catch (err) {
        setErrorState(key)
      } finally {
        loaded.value = true
        setLoading(false, key)
      }
    }

    const handleClose = async () => {
      try {
        const { data: companies } = await $axios.$get('/api/users/me/companies', {
          params: {},
          progress: false,
        })

        updateUser({ companies })
      } catch (err) {}

      modal.closeModal()
    }

    const { fetch } = useFetch(async () => {
      if (modal.isModalOpen()) {
        setLoading(true, 'get_invitations')
        try {
          const { data } = await $axios.$get('/api/users/me/invitations', {
            params: {},
            progress: false,
          })

          invites.value = data
        } catch (err) {
          fetchError.value = err
        } finally {
          loaded.value = true
          setLoading(false, 'get_invitations')
        }
      }
    })

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        if (val) {
          await nextTick()
          fetch()
        }
      }
    )

    return {
      props,
      ...modal,
      invites,
      loaded,
      fetchError,
      handleClose,
      reply,
      isLoading,
      hasErrorState,
    }
  },
})
