import { has as _has, get as _get, set as _set } from 'lodash-es'
import { add, sub, isAfter, differenceInCalendarDays, differenceInCalendarWeeks } from 'date-fns'
import { computed } from '@nuxtjs/composition-api'
import { nanoid } from 'nanoid/non-secure'

const getDefaultState = () => ({
  init: false,
  view: 'day',
  project: null,
  errors: null,
  tasks: [],
  tree: [],
  collapsed: [],
  childTree: {},
  mockTree: ['1', '2', '3'],
  mockTasks: [
    {
      id: '1',
      name: "Tâche d'exemple 1",
      type: 'task',
      isMock: true,
    },
    {
      id: '2',
      name: "Tâche d'exemple 2",
      type: 'task',
      isMock: true,
    },
    {
      id: '3',
      name: "Tâche d'exemple 3",
      type: 'task',
      isMock: true,
    },
  ],
})

export const state = () => getDefaultState()

export const getters = {
  getTask: (state) =>
    state.tasks.reduce((lines, item) => {
      lines[item.id] = item
      return lines
    }, {}),
  getMockTask: (state) =>
    state.mockTasks.reduce((lines, item) => {
      lines[item.id] = item
      return lines
    }, {}),
  getChildTree: (state) => (id) => _get(state.childTree, id, []),
  getCollapsed: (state) => (id) => state.collapsed.findIndex((el) => el === id) !== -1,
  getDateRange: (state) => {
    let defaultStart = sub(new Date(), { days: state.view === 'day' ? 1 : 7 })
    let defaultEnd = add(new Date(), { days: state.view === 'day' ? 30 : 30 })

    let { min, max, overflowBefore, overflowAfter } = state.tasks.reduce(
      (obj, task) => {
        if (obj.min === null) {
          if (task.type !== 'lot') {
            obj.min = sub(new Date(task.startAt || task.endAt), { days: state.view === 'day' ? 1 : 7 })
          }
        }

        if (task.startAt && new Date(task.startAt) < obj.min) {
          obj.min = new Date(task.startAt)
          obj.overflowBefore = true
        }

        if (task.endAt && new Date(task.endAt) > obj.max) {
          obj.max = new Date(task.endAt)
          obj.overflowAfter = true
        }

        return obj
      },
      { min: null, max: defaultEnd, overflowBefore: false, overflowAfter: false }
    )

    if (state.view === 'day' && differenceInCalendarDays(max, min) < 25) {
      max = add(max, { days: 20 })
    }

    if (state.view === 'day' && differenceInCalendarDays(defaultEnd, min) < 25) {
      defaultEnd = add(defaultEnd, { days: 20 })
    }

    if (state.view === 'week' && differenceInCalendarWeeks(max, min) < 8) {
      max = add(max, { weeks: 6 })
    }

    if (state.view === 'week' && differenceInCalendarWeeks(defaultEnd, min) < 8) {
      defaultEnd = add(defaultEnd, { weeks: 6 })
    }

    return {
      startDate: min || defaultStart,
      endDate: overflowAfter ? max : defaultEnd,
    }
  },
}

const sortTree = (tree, state) => {
  return tree.sort((a, b) => {
    let taskA = state.tasks.find((el) => el.id === a)
    let taskB = state.tasks.find((el) => el.id === b)

    if (taskA && taskB) {
      return isAfter(
        new Date(taskA.startAt || taskA.endAt || taskA.createdAt),
        new Date(taskB.startAt || taskB.endAt || taskB.createdAt)
      )
        ? 1
        : -1
    }
    return 1
  })
}

export const mutations = {
  init(state, val) {
    state.init = val
  },

  setProject(state, val) {
    state.project = val
  },

  setErrors(state, val) {
    state.errors = val
  },

  initView(state) {
    let fromStorage = localStorage.getItem(`planning_view`)
    state.view = fromStorage !== null ? fromStorage : 'day'
  },

  setView(state, view) {
    state.view = view
    localStorage.setItem(`planning_view`, view)
  },

  initCollapsed(state, project) {
    let fromStorage = localStorage.getItem(`project_${project}_collapsedTasks`)
    state.collapsed = fromStorage !== null ? fromStorage.split(',') : []
  },

  setCollapsed(state, params) {
    if (params.collapsed) {
      state.collapsed.push(params.id)
    } else {
      let taskIndex = state.collapsed.findIndex((el) => el === params.id)
      if (taskIndex !== -1) {
        state.collapsed.splice(taskIndex, 1)
      }
    }
    if (state.project) {
      localStorage.setItem(`project_${state.project}_collapsedTasks`, state.collapsed)
    }
  },

  createTask(state, task) {
    state.tasks.push(task)
  },

  insertChild(state, { parentId, taskId }) {
    if (parentId && taskId) {
      let childTree = state.childTree
      if (!_has(childTree, parentId)) childTree[parentId] = []

      childTree[parentId].push(taskId)
      // Sorts the childTree based on type, keeps milestones at end

      childTree[parentId] = sortTree([].concat(childTree[parentId]), state)
      /*childTree[parentId] = childTree[parentId].sort((a, b) => {
        let childA = state.tasks.find((el) => el.id === a)
        let childB = state.tasks.find((el) => el.id === b)

        return isAfter(new Date(childA.startAt || childA.endAt), new Date(childB.startAt || childBA.endAt))
          ? 1
          : -1
      })*/

      state.childTree = { ...childTree }

      state.tree = sortTree([].concat(state.tree), state)
    }
  },

  sortParent(state, parent) {
    let childTree = state.childTree
    if (!_has(childTree, parent.id)) childTree[parent.id] = []
    childTree[parent.id] = sortTree([].concat(childTree[parent.id]), state)
  },

  insertInTree(state, taskId) {
    if (taskId) {
      let tree = state.tree
      tree.push(taskId)

      // Sorts the tree based on type, keeps milestones at end
      state.tree = sortTree(tree, state)
    }
  },

  removeChild(state, { parentId, taskId }) {
    if (parentId && taskId) {
      let childTree = _get(state.childTree, parentId, [])

      const childIndex = childTree.findIndex((el) => el === taskId)
      if (childIndex !== -1) {
        childTree.splice(childIndex, 1)
      }
    }
  },

  removeFromTree(state, task) {
    if (task.id) {
      const taskIndex = state.tree.findIndex((el) => el === task.id)
      if (taskIndex !== -1) {
        state.tree.splice(taskIndex, 1)
      }
    }
  },

  removeTask(state, id) {
    let task = state.tasks.find((el) => el.id === id)

    if (task && task.type === 'lot') {
      let childIds = _get(state.childTree, id, [])
      delete state.childTree[id]

      // Removes children from tree
      for (const id of childIds) {
        const taskIndex = state.tasks.findIndex((el) => el.id === id)
        if (taskIndex !== -1) {
          state.tasks.splice(taskIndex, 1)
        }
      }
    }

    let index = state.tree.findIndex((el) => el === id)
    if (index !== -1) state.tree.splice(index, 1)

    let taskIndex = state.tasks.findIndex((el) => el.id === id)
    if (taskIndex !== -1) state.tasks.splice(taskIndex, 1)
  },

  updateTask(state, task) {
    const taskIndex = state.tasks.findIndex((el) => el.id === task.id)
    if (taskIndex !== -1) {
      state.tasks.splice(taskIndex, 1, task)
    }

    state.tree = sortTree([].concat(state.tree), state)
  },

  populateTasks(state, data) {
    state.tasks = _get(data, 'tasks', [])
    state.tree = _get(data, 'tree', [])
    state.childTree = _get(data, 'childTree', {})
  },

  resetState(state) {
    for (const [k, v] of Object.entries(getDefaultState())) {
      state[k] = v
    }
  },

  resetTasks(state) {
    state.tasks = []
    state.tree = []
    state.tcollapsed = []
    state.tchildTree = {}
  },
}

export const actions = {
  async load({ commit, dispatch }, params) {
    commit('resetState')
    commit('initView')

    if (params.project.id) {
      commit('setProject', params.project.id)
      commit('initCollapsed', params.project.id)

      const { company } = this.$auth

      try {
        const { data } = await this.$tasksRepository.list({
          params: {
            _limit: 100,
            _expand: ['tasks'],
            project: params.project.id,
          },
        })

        commit('populateTasks', flattenTasks(data))
        commit('init', true)
      } catch (err) {
        console.log(err)
      }
    }
  },
  // Handles update of a task
  onTaskUpdate({ state, dispatch, commit }, { data, metadata }) {
    dispatch('updateTask', data)

    if (data.parent) {
      dispatch('updateTask', data.parent)
      // Removes (maybe) task from root tree if setting a parent
      commit('removeFromTree', data)
    }

    if (metadata && _get(metadata, 'oldParent')) {
      dispatch('updateTask', metadata.oldParent)
      // Add task in base tree if parent was removed
      if (!data.parent && data.id) {
        commit('removeChild', { parentId: _get(metadata.oldParent, 'id', null), taskId: data.id })
        commit('insertInTree', data.id)
      }
    }
  },

  onTaskCreate({ state, commit, dispatch }, task) {
    const { parent, ...taskData } = task
    commit('createTask', taskData)

    if (parent) {
      dispatch('updateTask', parent)
      commit('insertChild', { parentId: _get(parent, 'id', null), taskId: _get(taskData, 'id', null) })
    } else {
      commit('insertInTree', _get(taskData, 'id', null))
    }
  },

  onTaskDelete({ state, commit, dispatch }, data) {
    if (data.parent) {
      const parentId = _get(data, 'parent.id', null)
      if (parentId) {
        commit('removeChild', { parentId, taskId: data.id })
        dispatch('updateTask', data.parent)
      }
    }

    commit('removeTask', data.id)
  },

  updateTask({ state, commit }, task) {
    if (task) {
      let { parent: newParent, ...taskData } = task
      let taskId = _get(taskData, 'id', null)

      commit('updateTask', taskData)

      let currentParentId = findParent(state, task)

      if (newParent) {
        let newParentId = _get(newParent, 'id', null)
        if (newParentId !== currentParentId) {
          commit('insertChild', { parentId: newParentId, taskId })
          commit('removeChild', { parentId: currentParentId, taskId })
        } else {
          commit('sortParent', newParent)
        }
      }
    }
  },
}

const findParent = (state, task) => Object.keys(state.childTree).find((key) => state.childTree[key].includes(task.id))

const flattenTask = (task, obj, parent = null) => {
  let { tasks, ...taskData } = task
  obj.tasks.push(taskData)

  if (parent === null) {
    obj.tree.push(taskData.id)
  } else {
    if (_has(obj.childTree, parent)) {
      obj.childTree[parent].push(taskData.id)
    } else {
      obj.childTree[parent] = [taskData.id]
    }
  }

  tasks
    .sort((a, b) =>
      // Sorts tasks based on type, keeps milestones at end
      //['lot', 'task', 'milestone'].indexOf(a.type) < ['lot', 'task', 'milestone'].indexOf(b.type) ? -1 : 1
      isAfter(new Date(a.startAt || a.endAt || a.createdAt), new Date(b.startAt || b.endAt || b.createdAt)) ? 1 : -1
    )
    .forEach((child) => {
      obj = { ...obj, ...flattenTask(child, obj, taskData.id) }
    })

  return obj
}

const flattenTasks = (tasks) =>
  tasks
    .sort((a, b) => {
      // Sorts tasks based on type, keeps milestones at end
      //['lot', 'task', 'milestone'].indexOf(a.type) < ['lot', 'task', 'milestone'].indexOf(b.type) ? -1 : 1
      return isAfter(new Date(a.startAt || a.endAt || a.createdAt), new Date(b.startAt || b.endAt || b.createdAt))
        ? 1
        : -1
    })
    .reduce(
      (obj, task) => {
        obj = { ...obj, ...flattenTask(task, obj) }
        return obj
      },
      { tasks: [], tree: [], childTree: {} }
    )
