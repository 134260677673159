import { get as _get } from 'lodash-es'

export default () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  if ([undefined, null].includes(_get(params, '_impersonate', null))) {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = '87699562-8e89-4cda-8c4a-4797ec2324bd'
    ;(function () {
      const d = document
      const s = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)

      window.$crisp.push(['safe', true])
      window.$crisp.push(['do', 'chat:hide'])
    })()
  }
}
