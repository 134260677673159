//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: 'bg-brand-500',
    },
    color: {
      type: String,
      default: 'text-brand-500',
    },
    inline: Boolean,
    data: [String, Number],
  },
})
