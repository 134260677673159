//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, nextTick, ref } from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import useHighlight from '@/composables/highlight'
import { PRODUCT_TYPES } from '@/constants/products'

import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useProductsSearch } from '@/composables/products'
const { useState, useActions } = createNamespacedHelpers('documents')

export default defineComponent({
  props: {
    line: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    descriptionText: {
      type: String,
      default: '',
    },
    context: {
      type: String,
      default: 'builder',
    },
    exclude: Array,
    parentBounding: Object,
  },
  setup(props, { emit, refs }) {
    const { isLoading } = useLoading()
    const { highlight } = useHighlight()

    const excludeIds = computed(() => props.exclude)
    const searchProducts = useProductsSearch({ emit, line: props.line, context: 'work' }, excludeIds)

    const boundary = ref(null)

    const handleResize = async () => {
      await nextTick()
      if (boundary.value) {
        let scrollContainer = boundary.value.closest('.modal-content').querySelector('.simplebar-content-wrapper')
        if (scrollContainer) {
          scrollContainer.dispatchEvent(new Event('scroll'))
        }
      }
    }

    const handleKeyDown = async ($event) => {
      if ($event.key === 'ArrowDown') {
        $event.target.blur()

        if (refs.resultsList && refs.resultsList.getElementsByClassName('result-line')[0]) {
          refs.resultsList.getElementsByClassName('result-line')[0].focus()
        }
      }
    }

    const handleListNav = function (e) {
      if (e.key === 'ArrowDown') {
        const nextSibling = e.target.nextSibling
        if (nextSibling !== null) {
          nextSibling.focus()
        }
      } else if (e.key === 'ArrowUp') {
        const previousSibling = e.target.previousSibling
        if (previousSibling !== null && previousSibling.tagName) {
          previousSibling.focus()
        } else {
          emit('focusEditor')
        }
      }
    }

    const groupedResults = computed(() => {
      const results = searchProducts.results.value

      const currentGroupResults = results.filter((el) => el.type === (props.line.type || props.line.element.type))
      const otherResults = results.filter((el) => el.type !== (props.line.type || props.line.element.type))

      return {
        current: currentGroupResults,
        other: otherResults,
      }
    })

    const handleSelectResult = ($event) => {
      if (props.context === 'builder') {
        searchProducts.selectResult($event)
      } else {
        emit('selectResult', $event)
        searchProducts.showSearch.value = false
      }
    }

    return {
      PRODUCT_TYPES,
      props,
      ...searchProducts,
      boundary,
      groupedResults,
      handleSelectResult,
      isLoading,
      handleListNav,
      handleKeyDown,
      handleResize,
      highlight,
    }
  },
})
