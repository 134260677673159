import { get as _get } from 'lodash-es'
import { useUser } from '@/composables/user'
import { useContext, computed } from '@nuxtjs/composition-api'
import { PRODUCT_TYPE_WORK_DETAILED, PRODUCT_TYPE_WORK } from '@/constants/products'
import { ACCOUNTING_MODE_SOLE_PROP } from '@/constants/companies'

export default function () {
  const { app } = useContext()
  const { planItems, company } = useUser()

  const getDefaultPlanItem = (productType) => {
    if (!planItems) return null
    if (productType === PRODUCT_TYPE_WORK_DETAILED) {
      productType = PRODUCT_TYPE_WORK
    }
    return (
      planItems.find(
        (item) => item.code.toString() === _get(company.value, `defaultProductPlanItems.${productType}`, '').toString()
      ) || null
    )
  }

  const getPlanItemOptions = (productType) => {
    if (!planItems)
      return [
        {
          value: null,
          label: app.i18n.t('products.field.placeholder.defaultPlanItem', {
            defaultPlanItem: getDefaultPlanItem(productType),
            company: company.value,
          }),
        },
      ]

    return planItems.reduce(
      (arr, item) => [
        ...arr,
        ...[
          {
            value: item.id,
            label: `${
              company.value.accountingMode === ACCOUNTING_MODE_SOLE_PROP ? item.simpleName : `${item.code} ${item.name}`
            }`,
          },
        ],
      ],
      [
        {
          value: null,
          label: app.i18n.t('products.field.placeholder.defaultPlanItem', {
            defaultPlanItem: getDefaultPlanItem(productType),
            company: company.value,
          }),
        },
      ]
    )
  }

  const getPlanItemName = (type) => {
    let planItem = planItems.find((el) => el.type === type)
    if (planItem) {
      return planItem.name
    }
    return null
  }

  return { getDefaultPlanItem, getPlanItemOptions, getPlanItemName }
}
