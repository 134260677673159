//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, watch, nextTick, inject } from '@nuxtjs/composition-api'
import { has as _has } from 'lodash-es'
export default defineComponent({
  props: {
    zIndex: Number,
    isOpen: {
      type: Boolean,
      default: false,
    },
    noPadding: Boolean,
    noScroll: Boolean,
    noCloseButton: Boolean,
    fixedHeader: Boolean,
    innerClass: String,
  },
  setup(props) {
    const shouldShowPanel = ref(false)
    const showPanel = ref(false)

    const panel = ref(null)

    watch(
      () => props.isOpen,
      async (val) => {
        await nextTick()
        if (val === true) {
          if (_has(window, '$crisp')) {
            window.$crisp.push(['do', 'chat:hide'])
          }
          shouldShowPanel.value = true
        } else {
          /*if (_has(window, '$crisp')) {
            window.$crisp.push(['do', 'chat:show'])
          }*/
          setTimeout(() => {
            shouldShowPanel.value = false
          }, 400)
        }
        showPanel.value = val
      }
    )

    return { props, panel, shouldShowPanel, showPanel }
  },
})
