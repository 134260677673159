//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { computed, defineComponent, ref, watch, useContext, useFetch, useRoute } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'
import { withQuery } from 'ufo/dist'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const PACKAGE_PLAN_SLUG = 'batichiffrage-all'
    const { $plansRepository, $axios, $toast, $bus, env } = useContext()
    const appURL = env.APP_URL

    const { subscription } = useUser()
    const route = useRoute()

    const modal = useModal()
    const interval = ref('year')
    const plans = ref([])

    const selectedPlans = ref([])

    const filteredPlans = computed(() =>
      plans.value
        .filter((el) => el.interval === interval.value)
        .sort((a, b) => {
          return a.slug === PACKAGE_PLAN_SLUG && b.slug !== PACKAGE_PLAN_SLUG ? -1 : 1
        })
    )

    const totalCost = computed(() =>
      selectedPlans.value.reduce((cost, slug) => {
        let plan = filteredPlans.value.find((el) => el.slug === slug)
        if (plan) cost += plan.price
        return cost
      }, 0)
    )

    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const openChat = () => {
      $bus.emit('openChat')
      modal.closeModal()
    }

    const { fetch, fetchState } = useFetch(async () => {
      if (modal.isModalOpen()) {
        try {
          const { data } = await $plansRepository.list({ params: { type: 'batichiffrage', _limit: 25 } })
          plans.value = data
        } catch (err) {
          console.log(err)
        } finally {
        }
      }
    })

    const selectPlan = (plan, value) => {
      if (value) {
        if (plan.slug === PACKAGE_PLAN_SLUG) {
          // Select only the package plan
          selectedPlans.value = [plan.slug]
        } else {
          // Deselect the package plan if selected
          let index = selectedPlans.value.findIndex((el) => el === PACKAGE_PLAN_SLUG)
          if (index !== -1) {
            selectedPlans.value.splice(index, 1)
          }
          selectedPlans.value.push(plan.slug)

          if (selectedPlans.value.length > 3) {
            selectedPlans.value = [PACKAGE_PLAN_SLUG]
          }
        }
      } else {
        let index = selectedPlans.value.findIndex((el) => el === plan.slug)
        if (index !== -1) {
          selectedPlans.value.splice(index, 1)
        }
      }
    }

    const subscribe = async (plan) => {
      try {
        setLoading(true, `subscribe_batichiffrage`)
        const { data } = await $axios.post(`api/billing/batichiffrage/subscribe`, {
          plans: selectedPlans.value,
          interval: interval.value,
          cancel_url: withQuery(`${appURL}${route.value.fullPath}`, { payment_cancel: 1 }),
          success_url: withQuery(`${appURL}`, { payment_success: 1, product: 'bc' }),
        })
        const url = _get(data, 'data.url', null)

        if (url !== null) {
          window.location.href = url
        } else {
          $toast.error('stripe error', {
            duration: 2000,
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, `subscribe_batichiffrage`)
      }
    }

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val) fetch()
      }
    )

    return {
      _get,
      props,
      ...modal,
      interval,
      plans,
      selectedPlans,
      filteredPlans,
      totalCost,
      subscription,
      fetchState,
      openChat,
      isLoading,
      selectPlan,
      subscribe,
      hasErrorState,
      PACKAGE_PLAN_SLUG,
    }
  },
})
