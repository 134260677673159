//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { useLoading } from '@/composables/loading'

import { computed, defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api'

import {
  PRODUCT_TYPE_MISC,
  PRODUCT_TYPE_WORK,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPES_CREATE,
  PRODUCT_TYPES_WORKS,
} from '@/constants/products'
import { useUser } from '@/composables/user'
import useAccounting from '@/composables/accounting'

import { ACCOUNTING_MODE_DISABLED } from '@/constants/companies'
import { get as _get } from 'lodash-es'

export default defineComponent({
  props: {
    item: Object,
  },
  setup(props, { emit }) {
    const { app } = useContext()
    const { company, defaultUnitObject } = useUser()
    const modal = useModal()
    const { loading } = useLoading()

    const form = ref({
      type: null,
      unit: null,
      planItem: null,
    })

    const formErrors = ref([])

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val) {
          form.value.type = props.item.element.type
          form.value.unit = props.item.element.unit
          if (props.item.element.planItem && company.value.accountingMode !== ACCOUNTING_MODE_DISABLED) {
            form.value.planItem = props.item.element.planItem
          }
        } else {
          form.value.type = null
          form.value.unit = null
          form.value.planItem = null
        }
      }
    )

    const update = function () {
      if (formErrors.value.length === 0) {
        if (form.value.type === PRODUCT_TYPE_WORKFORCE) {
          if (_get(form.value.unit, `symbol`) === _get(defaultUnitObject, `symbol`)) {
            let hourUnit = _get(company.value, 'units', []).find((el) => el.symbol === 'h')
            if (hourUnit) form.value.unit = hourUnit
          }
        }

        emit('update', {
          ...props.item,
          element: {
            ...props.item.element,
            ...form.value,
          },
        })
        modal.closeModal()
      }
    }

    const availableTypes = computed(() => {
      let types = PRODUCT_TYPES_CREATE

      switch (props.item.element.type) {
        case PRODUCT_TYPE_MISC:
          types = types.concat(PRODUCT_TYPE_MISC)
          break
        case PRODUCT_TYPE_WORK:
          types = types.concat(PRODUCT_TYPE_WORK)
          break
      }

      return types.filter((el) => el !== PRODUCT_TYPE_WORK_DETAILED)
    })

    const { getDefaultPlanItem, getPlanItemOptions } = useAccounting()
    const defaultPlanItem = computed(() => getDefaultPlanItem(form.value.type))
    const planItemOptions = computed(() => getPlanItemOptions(form.value.type))

    const handleType = (type) => {
      if (form.value.planItem !== null && company.value.accountingMode !== ACCOUNTING_MODE_DISABLED) {
        form.value.planItem = null
      }
    }

    return {
      ACCOUNTING_MODE_DISABLED,
      PRODUCT_TYPE_MISC,
      ...modal,
      company,
      planItemOptions,
      defaultPlanItem,
      form,
      formErrors,
      availableTypes,
      loading,
      update,
      handleType,
    }
  },
})
