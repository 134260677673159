const trimChar = function (string, toRemove) {
  while (string.charAt(0) === toRemove) {
    string = string.substring(1)
  }

  while (string.charAt(string.length - 1) === toRemove) {
    string = string.substring(0, string.length - 1)
  }

  return string
}

export const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')
export const roundToThree = (num) => +(Math.round(num + 'e+3') + 'e-3')
export const roundToPrecision = (num, precision) => +(Math.round(num + `e+${precision}`) + `e-${precision}`)
export const roundToSix = (num) => +(Math.round(num + 'e+6') + 'e-6')

/**
 * Outputs a value as as String.
 * Used by NumeralJs to create numbers.
 *
 * @param input
 * @returns {string}
 */
export const parseNumeral = (input) => trimChar(input.toString().replace('.', ','), ',')
