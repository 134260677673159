import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _50a1f2cf = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3d7eeea2 = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _a2ff4272 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _d85c8e08 = () => interopDefault(import('../pages/credits/index.vue' /* webpackChunkName: "pages/credits/index" */))
const _49479a24 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _c50a8f28 = () => interopDefault(import('../pages/holdbacks.vue' /* webpackChunkName: "pages/holdbacks" */))
const _b1eb71ae = () => interopDefault(import('../pages/invites.vue' /* webpackChunkName: "pages/invites" */))
const _3fe574cc = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _75c1691b = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _07f5db70 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5ca3818a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _0701809c = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _30b9ca52 = () => interopDefault(import('../pages/order-forms/index.vue' /* webpackChunkName: "pages/order-forms/index" */))
const _4c9be5c4 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _f5d522d0 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _fb455ba4 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _6f1b652b = () => interopDefault(import('../pages/quotes/index.vue' /* webpackChunkName: "pages/quotes/index" */))
const _724ac18e = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _851baaec = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _5e11b86e = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _7ca508e6 = () => interopDefault(import('../pages/supplier-credits/index.vue' /* webpackChunkName: "pages/supplier-credits/index" */))
const _6da9d529 = () => interopDefault(import('../pages/supplier-invoices/index.vue' /* webpackChunkName: "pages/supplier-invoices/index" */))
const _35c8baee = () => interopDefault(import('../pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _3893b6a0 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _541d9ec6 = () => interopDefault(import('../pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _50c506bd = () => interopDefault(import('../pages/account/company/index.vue' /* webpackChunkName: "pages/account/company/index" */))
const _26c0d0be = () => interopDefault(import('../pages/accounting/incomes.vue' /* webpackChunkName: "pages/accounting/incomes" */))
const _5b707a3a = () => interopDefault(import('../pages/account/company/accounting.vue' /* webpackChunkName: "pages/account/company/accounting" */))
const _f32b16ee = () => interopDefault(import('../pages/account/company/appearance.vue' /* webpackChunkName: "pages/account/company/appearance" */))
const _176ba522 = () => interopDefault(import('../pages/account/company/banks.vue' /* webpackChunkName: "pages/account/company/banks" */))
const _71390f74 = () => interopDefault(import('../pages/account/company/builder.vue' /* webpackChunkName: "pages/account/company/builder" */))
const _1176a5c5 = () => interopDefault(import('../pages/account/company/credits.vue' /* webpackChunkName: "pages/account/company/credits" */))
const _24cf0508 = () => interopDefault(import('../pages/account/company/emails.vue' /* webpackChunkName: "pages/account/company/emails" */))
const _1ac06e80 = () => interopDefault(import('../pages/account/company/footer.vue' /* webpackChunkName: "pages/account/company/footer" */))
const _24aa54da = () => interopDefault(import('../pages/account/company/info.vue' /* webpackChunkName: "pages/account/company/info" */))
const _35a5df45 = () => interopDefault(import('../pages/account/company/insurance.vue' /* webpackChunkName: "pages/account/company/insurance" */))
const _0f776d6a = () => interopDefault(import('../pages/account/company/invoices.vue' /* webpackChunkName: "pages/account/company/invoices" */))
const _cb93725e = () => interopDefault(import('../pages/account/company/misc.vue' /* webpackChunkName: "pages/account/company/misc" */))
const _49e6c99b = () => interopDefault(import('../pages/account/company/order-forms.vue' /* webpackChunkName: "pages/account/company/order-forms" */))
const _12ceba12 = () => interopDefault(import('../pages/account/company/payments.vue' /* webpackChunkName: "pages/account/company/payments" */))
const _6ce688c9 = () => interopDefault(import('../pages/account/company/products.vue' /* webpackChunkName: "pages/account/company/products" */))
const _5ca3d4e9 = () => interopDefault(import('../pages/account/company/profit.vue' /* webpackChunkName: "pages/account/company/profit" */))
const _291f52c8 = () => interopDefault(import('../pages/account/company/quotes.vue' /* webpackChunkName: "pages/account/company/quotes" */))
const _2d8740d7 = () => interopDefault(import('../pages/account/company/reminder.vue' /* webpackChunkName: "pages/account/company/reminder" */))
const _11bd9940 = () => interopDefault(import('../pages/account/company/signatures.vue' /* webpackChunkName: "pages/account/company/signatures" */))
const _15e18af6 = () => interopDefault(import('../pages/account/company/subscription/index.vue' /* webpackChunkName: "pages/account/company/subscription/index" */))
const _48c581d6 = () => interopDefault(import('../pages/account/company/tax.vue' /* webpackChunkName: "pages/account/company/tax" */))
const _0a7a761c = () => interopDefault(import('../pages/account/company/terms.vue' /* webpackChunkName: "pages/account/company/terms" */))
const _d3a23e1a = () => interopDefault(import('../pages/account/company/users.vue' /* webpackChunkName: "pages/account/company/users" */))
const _e177befa = () => interopDefault(import('../pages/account/company/subscription/invoices.vue' /* webpackChunkName: "pages/account/company/subscription/invoices" */))
const _83553222 = () => interopDefault(import('../pages/contacts/_id.vue' /* webpackChunkName: "pages/contacts/_id" */))
const _2e241432 = () => interopDefault(import('../pages/credits/_id/index.vue' /* webpackChunkName: "pages/credits/_id/index" */))
const _5d360385 = () => interopDefault(import('../pages/invoices/_id/index.vue' /* webpackChunkName: "pages/invoices/_id/index" */))
const _73bd2786 = () => interopDefault(import('../pages/order-forms/_id/index.vue' /* webpackChunkName: "pages/order-forms/_id/index" */))
const _c27ea0ba = () => interopDefault(import('../pages/products/_category.vue' /* webpackChunkName: "pages/products/_category" */))
const _2053efd4 = () => interopDefault(import('../pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))
const _7217f296 = () => interopDefault(import('../pages/quotes/_id/index.vue' /* webpackChunkName: "pages/quotes/_id/index" */))
const _2f12b331 = () => interopDefault(import('../pages/suppliers/_id.vue' /* webpackChunkName: "pages/suppliers/_id" */))
const _49fac7d2 = () => interopDefault(import('../pages/credits/_id/details.vue' /* webpackChunkName: "pages/credits/_id/details" */))
const _5d7dba35 = () => interopDefault(import('../pages/invoices/_id/details.vue' /* webpackChunkName: "pages/invoices/_id/details" */))
const _3931d4ed = () => interopDefault(import('../pages/order-forms/_id/details.vue' /* webpackChunkName: "pages/order-forms/_id/details" */))
const _7cbfadf4 = () => interopDefault(import('../pages/quotes/_id/details.vue' /* webpackChunkName: "pages/quotes/_id/details" */))
const _38e485c2 = () => interopDefault(import('../pages/express/_id/_token.vue' /* webpackChunkName: "pages/express/_id/_token" */))
const _325d6f31 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _50a1f2cf,
    name: "account___fr"
  }, {
    path: "/calendar",
    component: _3d7eeea2,
    name: "calendar___fr"
  }, {
    path: "/contacts",
    component: _a2ff4272,
    name: "contacts___fr"
  }, {
    path: "/credits",
    component: _d85c8e08,
    name: "credits___fr"
  }, {
    path: "/forgot-password",
    component: _49479a24,
    name: "forgot-password___fr"
  }, {
    path: "/holdbacks",
    component: _c50a8f28,
    name: "holdbacks___fr"
  }, {
    path: "/invites",
    component: _b1eb71ae,
    name: "invites___fr"
  }, {
    path: "/invoices",
    component: _3fe574cc,
    name: "invoices___fr"
  }, {
    path: "/join",
    component: _75c1691b,
    name: "join___fr"
  }, {
    path: "/login",
    component: _07f5db70,
    name: "login___fr"
  }, {
    path: "/logout",
    component: _5ca3818a,
    name: "logout___fr"
  }, {
    path: "/maintenance",
    component: _0701809c,
    name: "maintenance___fr"
  }, {
    path: "/order-forms",
    component: _30b9ca52,
    name: "order-forms___fr"
  }, {
    path: "/payments",
    component: _4c9be5c4,
    name: "payments___fr"
  }, {
    path: "/products",
    component: _f5d522d0,
    name: "products___fr"
  }, {
    path: "/projects",
    component: _fb455ba4,
    name: "projects___fr"
  }, {
    path: "/quotes",
    component: _6f1b652b,
    name: "quotes___fr"
  }, {
    path: "/referral",
    component: _724ac18e,
    name: "referral___fr"
  }, {
    path: "/reset-password",
    component: _851baaec,
    name: "reset-password___fr"
  }, {
    path: "/signup",
    component: _5e11b86e,
    name: "signup___fr"
  }, {
    path: "/supplier-credits",
    component: _7ca508e6,
    name: "supplier-credits___fr"
  }, {
    path: "/supplier-invoices",
    component: _6da9d529,
    name: "supplier-invoices___fr"
  }, {
    path: "/suppliers",
    component: _35c8baee,
    name: "suppliers___fr"
  }, {
    path: "/tasks",
    component: _3893b6a0,
    name: "tasks___fr"
  }, {
    path: "/transactions",
    component: _541d9ec6,
    name: "transactions___fr"
  }, {
    path: "/account/company",
    component: _50c506bd,
    name: "account-company___fr"
  }, {
    path: "/accounting/incomes",
    component: _26c0d0be,
    name: "accounting-incomes___fr"
  }, {
    path: "/account/company/accounting",
    component: _5b707a3a,
    name: "account-company-accounting___fr"
  }, {
    path: "/account/company/appearance",
    component: _f32b16ee,
    name: "account-company-appearance___fr"
  }, {
    path: "/account/company/banks",
    component: _176ba522,
    name: "account-company-banks___fr"
  }, {
    path: "/account/company/builder",
    component: _71390f74,
    name: "account-company-builder___fr"
  }, {
    path: "/account/company/credits",
    component: _1176a5c5,
    name: "account-company-credits___fr"
  }, {
    path: "/account/company/emails",
    component: _24cf0508,
    name: "account-company-emails___fr"
  }, {
    path: "/account/company/footer",
    component: _1ac06e80,
    name: "account-company-footer___fr"
  }, {
    path: "/account/company/info",
    component: _24aa54da,
    name: "account-company-info___fr"
  }, {
    path: "/account/company/insurance",
    component: _35a5df45,
    name: "account-company-insurance___fr"
  }, {
    path: "/account/company/invoices",
    component: _0f776d6a,
    name: "account-company-invoices___fr"
  }, {
    path: "/account/company/misc",
    component: _cb93725e,
    name: "account-company-misc___fr"
  }, {
    path: "/account/company/order-forms",
    component: _49e6c99b,
    name: "account-company-order-forms___fr"
  }, {
    path: "/account/company/payments",
    component: _12ceba12,
    name: "account-company-payments___fr"
  }, {
    path: "/account/company/products",
    component: _6ce688c9,
    name: "account-company-products___fr"
  }, {
    path: "/account/company/profit",
    component: _5ca3d4e9,
    name: "account-company-profit___fr"
  }, {
    path: "/account/company/quotes",
    component: _291f52c8,
    name: "account-company-quotes___fr"
  }, {
    path: "/account/company/reminder",
    component: _2d8740d7,
    name: "account-company-reminder___fr"
  }, {
    path: "/account/company/signatures",
    component: _11bd9940,
    name: "account-company-signatures___fr"
  }, {
    path: "/account/company/subscription",
    component: _15e18af6,
    name: "account-company-subscription___fr"
  }, {
    path: "/account/company/tax",
    component: _48c581d6,
    name: "account-company-tax___fr"
  }, {
    path: "/account/company/terms",
    component: _0a7a761c,
    name: "account-company-terms___fr"
  }, {
    path: "/account/company/users",
    component: _d3a23e1a,
    name: "account-company-users___fr"
  }, {
    path: "/account/company/subscription/invoices",
    component: _e177befa,
    name: "account-company-subscription-invoices___fr"
  }, {
    path: "/contacts/:id",
    component: _83553222,
    name: "contacts-id___fr"
  }, {
    path: "/credits/:id",
    component: _2e241432,
    name: "credits-id___fr"
  }, {
    path: "/invoices/:id",
    component: _5d360385,
    name: "invoices-id___fr"
  }, {
    path: "/order-forms/:id",
    component: _73bd2786,
    name: "order-forms-id___fr"
  }, {
    path: "/products/:category",
    component: _c27ea0ba,
    name: "products-category___fr"
  }, {
    path: "/projects/:id",
    component: _2053efd4,
    name: "projects-id___fr"
  }, {
    path: "/quotes/:id",
    component: _7217f296,
    name: "quotes-id___fr"
  }, {
    path: "/suppliers/:id",
    component: _2f12b331,
    name: "suppliers-id___fr"
  }, {
    path: "/credits/:id/details",
    component: _49fac7d2,
    name: "credits-id-details___fr"
  }, {
    path: "/invoices/:id/details",
    component: _5d7dba35,
    name: "invoices-id-details___fr"
  }, {
    path: "/order-forms/:id?/details",
    component: _3931d4ed,
    name: "order-forms-id-details___fr"
  }, {
    path: "/quotes/:id/details",
    component: _7cbfadf4,
    name: "quotes-id-details___fr"
  }, {
    path: "/express/:id?/:token?",
    component: _38e485c2,
    name: "express-id-token___fr"
  }, {
    path: "/",
    component: _325d6f31,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
