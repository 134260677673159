//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, nextTick, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    disabled: Boolean,
    focus: Boolean,
    block: Boolean,
    wrap: Boolean,
    icon: {
      type: Array,
      default: null,
    },
    iconClass: String,
    loading: Boolean,
    noFocus: Boolean,
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (!props.disabled) {
        emit('clicked')
      }
    }

    const handleKeydown = ($event) => {
      emit('clicked')
    }

    const button = ref(null)

    onMounted(async () => {
      if (props.focus) {
        await nextTick()
        if (button.value) button.value.focus()
      }
    })
    return { props, button, handleClick, handleKeydown }
  },
})
