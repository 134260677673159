export const PRODUCT_TYPE_SUPPLY = 'supply'
export const PRODUCT_TYPE_MATERIAL = 'material'
export const PRODUCT_TYPE_WORK = 'work'
export const PRODUCT_TYPE_WORK_DETAILED = 'work_detailed'
export const PRODUCT_TYPE_WORKFORCE = 'workforce'
export const PRODUCT_TYPE_SUBCONTRACT = 'subcontract'
export const PRODUCT_TYPE_MISC = 'misc'

export const PRODUCT_TYPES_WORKS = [PRODUCT_TYPE_WORK, PRODUCT_TYPE_WORK_DETAILED]

export const PRODUCT_TYPES = [
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_WORK,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPE_SUBCONTRACT,
  PRODUCT_TYPE_MISC,
]

export const PRODUCT_TYPES_CREATE = [
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_SUBCONTRACT,
]

export const BUILDER_PRODUCT_TYPES = [
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_WORKFORCE,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_SUBCONTRACT,
]

export const getProductIcon = {
  [PRODUCT_TYPE_SUPPLY]: ['far', 'toolbox'],
  [PRODUCT_TYPE_MATERIAL]: ['far', 'helmet-safety'],
  [PRODUCT_TYPE_WORK]: ['far', 'block-brick'],
  [PRODUCT_TYPE_WORK_DETAILED]: ['far', 'block-brick'],
  [PRODUCT_TYPE_WORKFORCE]: ['far', 'id-badge'],
  [PRODUCT_TYPE_SUBCONTRACT]: ['far', 'user-helmet-safety'],
  [PRODUCT_TYPE_MISC]: ['far', 'hammer'],
  text: ['far', 'align-left'],
}
