import { render, staticRenderFns } from "./DocumentAddPaymentModal.vue?vue&type=template&id=6adaad6f&"
import script from "./DocumentAddPaymentModal.vue?vue&type=script&lang=js&"
export * from "./DocumentAddPaymentModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalCloseButton: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/ModalCloseButton.vue').default,PlanBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/PlanBadge.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,V3DatePicker: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/v3datepicker/V3DatePicker.vue').default,CurrencyInput: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/CurrencyInput.vue').default,FeatureLockWrapper: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/FeatureLockWrapper.vue').default,Modal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Modal.vue').default})
