//import { createNamespacedHelpers } from 'vuex-composition-helpers'
//const { useMutations, useState } = createNamespacedHelpers('loading')

import { createGlobalState } from '@vueuse/core'
import { ref } from '@nuxtjs/composition-api'

export const useLoading = createGlobalState(() => {
  const loaders = ref([])

  const setLoader = ({ key, val }) => {
    const index = loaders.value.findIndex((el) => el === key)
    if (val && index === -1) {
      loaders.value.push(key)
    } else if (index !== -1) {
      loaders.value.splice(index, 1)
    }
  }

  function isLoading(key) {
    return loaders.value.findIndex((el) => el === key) !== -1
  }

  function setLoading(val = true, key = null, timeout = null) {
    setLoader({ key, val })
  }

  return { setLoading, isLoading, loaders }
})

export const useErrorState = createGlobalState(() => {
  const errors = ref([])

  const setError = ({ key, val }) => {
    const index = errors.value.findIndex((el) => el === key)
    if (val && index === -1) {
      errors.value.push(key)
    } else if (index !== -1) {
      errors.value.splice(index, 1)
    }
  }

  function hasErrorState(key) {
    return errors.value.findIndex((el) => el === key) !== -1
  }

  function setErrorState(key = null) {
    setError({ key, val: true })
    setTimeout(() => {
      setError({ key, val: false })
    }, 3000)
  }

  return { setErrorState, hasErrorState }
})

/*export function useErrorState() {
  const errorState = ref(false)
  const errorStates = ref([])

  function hasErrorState(key) {
    return errorStates.value.findIndex((el) => el === key) !== -1
  }

  function setErrorState(key = null) {
    if (key === null) {
      errorState.value = true
    } else {
      errorStates.value.push(key)
    }

    setTimeout(() => {
      if (key === null) {
        errorState.value = false
      } else {
        const index = errorStates.value.findIndex((el) => el === key)
        errorStates.value.splice(index, 1)
      }
    }, 3000)
  }

  return { errorState, errorStates, setErrorState, hasErrorState }
}*/
