//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has, debounce as _debounce } from 'lodash-es'
import { defineComponent, ref, useContext, watch, computed, provide } from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import { pluralizeType } from '@/api/resources'
import { useUser } from '@/composables/user'
import Teleport from 'vue2-teleport'
export default defineComponent({
  components: {
    Teleport,
  },
  setup() {
    const { $axios } = useContext()
    const { company } = useUser()
    const { isLoading, setLoading } = useLoading()

    const search = ref(null)
    const showDropdown = ref(false)
    const results = ref([])

    provide('showDropdown', showDropdown)

    const visibleResults = computed(() =>
      Object.entries(results.value).reduce((obj, [k, v]) => {
        if (v.length) {
          obj[k] = v
        }
        return obj
      }, {})
    )

    const getResultComponent = (group) =>
      _get(
        {
          documents: 'GlobalSearchDocument',
          invoices: 'GlobalSearchDocument',
          quotes: 'GlobalSearchDocument',
          products: 'GlobalSearchProduct',
          contacts: 'GlobalSearchContact',
          projects: 'GlobalSearchProject',
        },
        group,
        null
      )

    function openDropdown() {
      if (search.value && visibleResults.value) {
        showDropdown.value = true
      }
    }

    let cancelTokenSource = null

    const runSearch = _debounce(async function () {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
      }
      cancelTokenSource = $axios.CancelToken.source()

      try {
        setLoading(true, 'global_search')
        const { data } = await $axios.get('search.php', {
          params: {
            limit: 50,
            q: search.value,
            c: company.value.id,
          },
          cancelToken: cancelTokenSource.token,
        })

        results.value = Object.values(data).reduce((obj, document) => {
          const group = pluralizeType[document.document_type]

          if (_has(obj, group)) {
            obj[group].push(document)
          } else {
            obj[group] = [document]
          }
          return obj
        }, {})

        showDropdown.value = true

        // Internal search
        //const { data } = await $axios.get(`api/search/?q=${search.value}`)
        //results.value = data.data
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, 'global_search')
        showDropdown.value = search.value !== ''
      }
    }, 120)

    watch(search, async (val) => {
      if (val) {
        await runSearch()
      }
      showDropdown.value = val !== ''
    })

    return { showDropdown, search, results, visibleResults, isLoading, getResultComponent, openDropdown }
  },
})
