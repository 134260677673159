//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    phone: {
      type: String,
      default: null,
    },
    starred: Boolean,
    iconOnly: Boolean,
  },
})
