//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import useModal from '@/composables/modal'

import { useErrorState, useLoading } from '@/composables/loading'

import { defineComponent, ref, useContext, useFetch, watch, nextTick } from '@nuxtjs/composition-api'

import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useActions, useState } = createNamespacedHelpers('documents')

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { nanoid } from 'nanoid/non-secure'

export default defineComponent({
  components: {
    VuePdfEmbed,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { setLoading, isLoading, loaders } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()

    const error = ref(null)
    const rendered = ref(false)
    const galleryKey = ref(nanoid())

    const handlePdfRendered = async () => {
      setLoading(false, 'preview_pdf')
      await nextTick()
      rendered.value = true
    }

    const handlePdfRenderingFailed = () => {
      error.value = `<span class="block font-semibold mb-2">Erreur lors du chargement de l'aperçu.</span>Veuillez réessayer, si le problème persiste, veuillez nous contacter.`
      setLoading(false, 'preview_pdf')
    }

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        error.value = null
        if (val) {
          if (props.file.type === 'pdf') {
            rendered.value = false
            setLoading(true, 'preview_pdf')
          } else {
            galleryKey.value = nanoid()
            setLoading(false, 'preview_pdf')
            rendered.value = true
          }
        } else {
          emit('close')
        }
      }
    )

    return {
      props,
      ...modal,
      rendered,
      error,
      loaders,
      galleryKey,
      _get,
      isLoading,
      handlePdfRendered,
      handlePdfRenderingFailed,
    }
  },
})
