//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'
import { get as _get } from 'lodash-es'

import { useUser } from '@/composables/user'
import { useState } from 'vuex-composition-helpers'
import useModal from '@/composables/modal'

export default defineComponent({
  setup() {
    const modal = useModal()
    const { user, company, subscription } = useUser()

    return { user, ...modal, subscription, company }
  },
})
