import { breakpointsTailwind, useWindowSize, useMediaQuery } from '@vueuse/core'
import { computed, ref } from '@nuxtjs/composition-api'

export default function useMq(emit) {
  const isSm = ref(useMediaQuery(`(min-width: ${breakpointsTailwind['sm']}px)`))
  const isMd = ref(useMediaQuery(`(min-width: ${breakpointsTailwind['md']}px)`))
  const isLg = ref(useMediaQuery(`(min-width: ${breakpointsTailwind['lg']}px)`))
  const isXl = ref(useMediaQuery(`(min-width: ${breakpointsTailwind['xl']}px)`))
  const is2Xl = ref(useMediaQuery(`(min-width: ${breakpointsTailwind['2xl']}px)`))

  const mq = computed(() => {
    if (is2Xl.value) return 'xl'
    if (isXl.value) return 'xl'
    if (isLg.value) return 'lg'
    if (isMd.value) return 'md'
    if (isSm.value) return 'sm'
    return 'xs'
  })

  return { mq }
}
