//
//
//
//
//

import { STATUS_RELEASED, STATUS_PAID, STATUS_ON_HOLD, STATUS_CANCELLED } from '@/constants/holdbacks'
import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    holdback: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const badgeClass = computed(() => {
      switch (props.holdback.status) {
        case STATUS_RELEASED:
          return 'bg-blue-100 text-blue-500'
        case STATUS_PAID:
          return 'bg-green-100 text-green-500'
        case STATUS_ON_HOLD:
          return 'bg-yellow-100 text-yellow-500'
        case STATUS_CANCELLED:
          return 'bg-gray-50 border border-gray-200 text-gray-400'
        default:
          return ''
      }
    })

    return { props, badgeClass }
  },
})
