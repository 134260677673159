//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { computed, defineComponent, nextTick, ref, useContext, useFetch, watch } from '@nuxtjs/composition-api'
import {
  CLIENT_TYPES,
  CONTACT_LEGAL_STATUS_COMPANY,
  CONTACT_LEGAL_STATUS_INDIVIDUAL,
  CONTACT_TYPE_SUBCONTRACTOR,
  CONTACT_TYPE_SUPPLIER,
} from '@/constants/contacts'
import { get as _get } from 'lodash-es'
import parsePhoneNumber from 'libphonenumber-js'
import { getPhoneNumber } from '@/utils/normalizers'
import { useLoading } from '@/composables/loading'
import Teleport from 'vue2-teleport'
import { useUser } from '@/composables/user'

export default defineComponent({
  components: {
    Teleport,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { $bus } = useContext()

    const modal = useModal()

    const trans = 'contacts.'
    const { loaders } = useLoading()

    const { can } = useUser()
    const tabs = computed(() => ['quotes', 'invoices', 'credits'].filter((el) => can('role', `${el}.read`)))

    const currentTab = ref(_get(tabs.value, '0', null))

    const showDocuments = ref(false)
    const showProjects = ref(false)
    const contactModal = ref(null)
    const deleteModal = ref(null)
    const popperBoundary = ref(null)
    const contact = ref(null)

    const hasAddress = computed(
      () => contact.value.address !== null && Object.values(contact.value.address).filter(Boolean).length > 0
    )

    const handleUpdateContact = (data) => {
      $bus.emit('update.item', data)
      contact.value = data
    }

    const handleDeleteContact = () => {
      modal.closeModal()
    }

    const hasContactData = (contact) => contact.firstName || contact.lastName

    const { $contactsRepository } = useContext()

    const { fetch, fetchState } = useFetch(async () => {
      if (modal.isModalOpen()) {
        const { data } = await $contactsRepository.find(props.id)
        contact.value = data

        if (data.notes) {
          showNotes.value = true
          showNotesButton.value = false
        }
      }
    })

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val === true) {
          showNotes.value = false
          showNotesButton.value = true
          fetch()
        }
      }
    )

    const phoneNumbers = computed(() =>
      contact.value.phones.map((el) => ({ ...el, country: _get(parsePhoneNumber(el.phone), 'country', null) }))
    )

    const hasData = computed(() => {
      return (
        (contact.value.legalStatus === CONTACT_LEGAL_STATUS_COMPANY &&
          (contact.value.taxId || _get(contact.value, 'contacts', []).length)) ||
        hasAddress.value ||
        contact.value.emails.length > 0
      )
    })

    const showNotes = ref(false)
    const showNotesButton = ref(true)
    const notesContainer = ref(null)

    const toggleNotes = async () => {
      showNotes.value = true
      showNotesButton.value = false
      await nextTick()
      if (notesContainer.value) {
        notesContainer.value.focus()
      }
    }

    return {
      ...modal,
      loaders,
      props,
      hasAddress,
      hasData,
      CONTACT_LEGAL_STATUS_INDIVIDUAL,
      CONTACT_TYPE_SUPPLIER,
      CONTACT_TYPE_SUBCONTRACTOR,
      CLIENT_TYPES,
      trans,
      contact,
      fetchState,
      tabs,
      currentTab,
      deleteModal,
      contactModal,
      popperBoundary,
      phoneNumbers,
      showDocuments,
      showProjects,
      showNotes,
      showNotesButton,
      notesContainer,
      toggleNotes,
      hasContactData,
      handleUpdateContact,
      handleDeleteContact,
      getPhoneNumber,
      parsePhoneNumber,
    }
  },
})
