//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, ref, useFetch, watch, nextTick } from '@nuxtjs/composition-api'

import { useUser } from '@/composables/user'
import { useLoading } from '@/composables/loading'

export default defineComponent({
  setup() {
    const { app } = useContext()
    const { setLoading, isLoading } = useLoading()
    const { user, updateUser } = useUser()
    const selectedTab = ref('all')

    const isShown = ref(false)
    const scrollContainer = ref(null)

    const handleShow = () => {
      isShown.value = true
    }

    const updateReadStatus = () => {
      isShown.value = false
      updateUser({ notificationsReadAt: new Date(), notificationsCount: 0 })
    }

    return {
      app,
      selectedTab,
      user,
      scrollContainer,
      isShown,
      handleShow,
      updateReadStatus,
      isLoading,
    }
  },
})
