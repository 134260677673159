//
//
//
//
//
//
//
//

import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    return {}
  },
})
