var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDropdown',{staticClass:"inline text-xs",attrs:{"distance":8,"triggers":_vm.$auth.can('role', (_vm.pluralType + ".write")) && _vm.hasDropdown ? ['click'] : []},scopedSlots:_vm._u([(_vm.hasDropdown)?{key:"popper",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"text-sm p-3 flex items-center"},[_c('p',{staticClass:"text-gray-500"},[_vm._v("Marquer comme")]),_vm._v(" "),_c('div',{staticClass:"ml-2 space-x-1"},_vm._l((_vm.availableStatuses),function(status){return _c('button',{key:("document_" + (_vm.document.id) + "_status_" + status),staticClass:"inline-block whitespace-nowrap badge rounded-full px-3 py-1 text-xs cursor-pointer",class:[_vm.getStatusClass(status)],attrs:{"type":"button"},on:{"click":function () {
              if (status === _vm.DOCUMENT_STATUS_PAID) {
                // && can('plan', 'documents.payments')
                _vm.$bus.emit('addPayment', _vm.document)
                hide()
              } else {
                _vm.changeStatus(status, hide)
              }
            }}},[_vm._v("\n          "+_vm._s(_vm.$t((_vm.pluralType + ".label.status." + status)))+"\n          "),(_vm.isLoading(status))?_c('fa-icon',{attrs:{"icon":['far', 'spinner-third'],"spin":""}}):_vm._e(),_vm._v(" "),(_vm.hasErrorState(status))?_c('fa-icon',{attrs:{"icon":['far', 'triangle-exclamation']}}):_vm._e()],1)}),0)])]}}:null],null,true)},[_c('span',{staticClass:"inline-block whitespace-nowrap badge rounded-full",class:[_vm.labelClass, _vm.statusClass, _vm.hasDropdown ? 'cursor-pointer' : '']},[_vm._v("\n    "+_vm._s(_vm.$t((_vm.pluralType + ".label.status." + (_vm.document.status))))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }