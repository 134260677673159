//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'
import { plansColor } from '@/config/plans'
import { useUser } from '@/composables/user'
export default defineComponent({
  props: {
    feature: String,
  },
  setup(props) {
    const { requiredPlan } = useUser()
    const plan = computed(() => requiredPlan(props.feature))
    return { props, plan, plansColor }
  },
})
