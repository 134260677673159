//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, watch, ref, nextTick, onMounted } from '@nuxtjs/composition-api'
import { nanoid } from 'nanoid'
import { get as _get } from 'lodash-es'

export default defineComponent({
  props: {
    value: String,
    options: Array,
  },
  setup(props, { emit }) {
    const innerValue = ref(null)
    const scrollAnchor = ref(null)
    const scrollContainer = ref(null)
    const id = nanoid()

    const handleShow = async () => {
      await nextTick()
      const anchor = _get(scrollAnchor.value, '0', null)
      if (anchor && scrollContainer.value) {
        let top = anchor.offsetTop
        scrollContainer.value.scrollTop = top - 24
      }
    }

    watch(
      () => props.value,
      (val) => {
        innerValue.value = val
      }
    )

    onMounted(() => {
      innerValue.value = props.value
    })

    return { props, scrollContainer, scrollAnchor, innerValue, id, handleShow }
  },
})
