//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, useRoute } from '@nuxtjs/composition-api'

export default defineComponent({
  props: ['error'],
  layout: 'error',
  setup(props) {
    const route = useRoute()

    const reloadPage = () => window.location.reload()
    onMounted(() => {
      window.onbeforeunload = undefined
    })
    return { props, reloadPage }
  },
})
