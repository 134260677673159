//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    role: String,
    padding: {
      type: String,
      default: 'px-2 py-1',
    },
  },
  setup(props) {
    const colors = {
      ROLE_OWNER: 'bg-green-100 border border-green-100 !text-green-600',
      ROLE_ADMIN: 'bg-yellow-100 border border-yellow-100 text-yellow-600',
      ROLE_ACCOUNTANT: 'bg-[#f8d3db] border border-[#f8d3db] text-[#e45172]',
      ROLE_QUOTE_MANAGER: 'bg-blue-100 border border-blue-100 text-blue-500',
      ROLE_SALES_MANAGER: 'bg-brand-100 border border-brand-100 text-brand-500',
      ROLE_GHOST: 'bg-gray-50 border border-gray-200 !text-gray-600',
      ROLE_COMPANION: 'bg-orange-100 border border-orange-100 !text-orange-600',
    }

    return { colors }
  },
})
