//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, ref, computed, reactive, watch, inject } from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'
import { get as _get, has as _has } from 'lodash-es'
import useModal from '@/composables/modal'

import {
  PRODUCT_TYPE_MISC,
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_SUBCONTRACT,
  PRODUCT_TYPES,
} from '@/constants/products'

import { handleBackendFormError, hasFormErrors } from '@/utils/error-handler'
import { useUser } from '@/composables/user'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const pluralType = computed(() => pluralizeType[`supplier_invoice`])
    const modal = useModal()

    const { app, i18n, $bus, $toast, $supplierInvoicesRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()
    const { company } = useUser()

    const defaultForm = () => ({
      name: null,
      total: null,
      subtotal: null,
      issuedAt: new Date(),
      category: PRODUCT_TYPE_MISC,
    })

    const categories = [PRODUCT_TYPE_SUPPLY, PRODUCT_TYPE_MATERIAL, PRODUCT_TYPE_SUBCONTRACT, PRODUCT_TYPE_MISC]

    const defaultErrors = () => ({
      subtotal: false,
      category: false,
    })

    const form = reactive(defaultForm())
    const errors = reactive(defaultErrors())

    const formErrors = reactive(
      Object.keys(defaultForm()).reduce((obj, key) => {
        obj[key] = false
        return obj
      }, {})
    )

    const init = ref(false)
    const invoiceId = ref(null)

    function submitFormRaw() {
      Object.assign(errors, defaultErrors())
      Object.assign(
        formErrors,
        Object.keys(defaultForm()).reduce((obj, key) => {
          obj[key] = false
          return obj
        }, {})
      )

      if (form.subtotal === null) {
        errors.subtotal = 'Veuillez indiquer un montant'
      }
    }

    const resetForm = () => {
      Object.assign(form, defaultForm())
    }
    const submit = async () => {
      submitFormRaw()

      if (!hasFormErrors(errors)) {
        setLoading(true, 'create_supplierInvoice')
        try {
          let total = _get(form, 'total', null)
          if (total) total = Math.round(total * 10000) / 100

          let subtotal = _get(form, 'subtotal', null)
          if (subtotal) subtotal = Math.round(subtotal * 10000) / 100

          let payload = {
            ...form,
            type: 'other',
            subtotal,
            total,
          }

          if (!invoiceId.value) {
            // Only set project when creating an expense
            payload.projects = [{ projectId: _get(props.project, 'id', null), weight: 100 }]
          }

          const { data } = invoiceId.value
            ? await $supplierInvoicesRepository.update(invoiceId.value, payload, { _expand: ['projects'] })
            : await $supplierInvoicesRepository.create(payload, { _expand: ['projects'] })

          $toast.show(props.mode === 'create' ? 'La dépense a été enregistrée' : 'La dépense a été mise à jour')

          try {
            $bus.emit(invoiceId.value ? 'update.item' : 'create.item', { type: 'supplier_invoice', data })
          } catch (err) {}

          emit('updated', data)
          modal.closeModal()
        } catch (err) {
          console.log(err)
          Object.assign(formErrors, handleBackendFormError(app, err))
          setErrorState('create_supplierInvoice')
        } finally {
          setLoading(false, 'create_supplierInvoice')
        }
      }
    }

    const initForm = (data) => {
      for (const key of Object.keys(defaultForm())) {
        if (_has(data, key)) {
          let val = data[key]
          if (['total', 'subtotal'].includes(key) && val !== null) {
            val /= 100
          }
          form[key] = val
        }
      }
      init.value = true
    }

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        if (val) {
          resetForm()
          Object.assign(errors, defaultErrors())
          if (props.id) {
            invoiceId.value = props.id
            try {
              const { data } = await $supplierInvoicesRepository.find(props.id)
              initForm(data)
            } catch (err) {
              console.log(err)
            }
          } else {
            invoiceId.value = null
          }
        }
      }
    )

    return {
      ...modal,
      pluralType,
      init,
      form,
      formErrors,
      errors,
      categories,
      company,
      submit,
      isLoading,
      hasErrorState,
      PRODUCT_TYPES,
      _get,
    }
  },
})
