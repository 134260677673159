import { onMounted, ref } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'

export default function useOnboarding() {
  const onboardingModal = ref(null)
  const onboardingType = ref('global')

  onMounted(() => {
    onboardingModal.value.triggerOnboarding()
  })

  return { onboardingModal, onboardingType }
}
