//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { get as _get, has as _has } from 'lodash-es'
export default defineComponent({
  props: {
    hasPadding: {
      type: Boolean,
      default: false,
    },
    workItems: {
      type: Array,
      default: () => [{ name: '' }],
    },
  },
  setup(props, { emit }) {
    const emitUpdate = function () {
      emit('update', props.workItems)
    }

    const removeWorkItem = function (index) {
      props.workItems.splice(index, 1)
      emitUpdate()
    }

    const workItemInput = function (index, e) {
      if (props.workItems[index]) {
        props.workItems[index].name = e.target.value
        if (!_has(props.workItems, index + 1)) {
          props.workItems.push({ name: '' })
        } else if (e.target.value === '') {
          if (_has(props.workItems, index + 1) && _get(props.workItems[index + 1], 'name', '') === '') {
            props.workItems.splice(index + 1, 1)
          }
        }
      }

      emitUpdate()
    }

    const nextWorkItem = function (index) {
      let next = _get(this, `$refs.work_item_${index + 1}[0]`)
      if (next) next.focus()
    }

    return { removeWorkItem, workItemInput, nextWorkItem }
  },
})
