import { onBeforeUnmount, onMounted, ref, useContext } from '@nuxtjs/composition-api'
import { useGlobalLogin, useUser } from '@/composables/user'

export default function useLayout() {
  const sidebarOpen = ref(false)
  const { user } = useUser()

  const { $bus } = useContext()
  const globalLogin = useGlobalLogin($bus)

  const switchColorMode = () => {
    // TODO implement
    //this.$colorMode.preference = this.$colorMode.preference === 'light' ? 'dark' : 'light'
  }

  onMounted(() => {
    $bus.on('toggleSidebar', () => {
      sidebarOpen.value = !sidebarOpen.value
    })
  })

  onBeforeUnmount(() => {
    $bus.off('toggleSidebar')
  })

  return { sidebarOpen, switchColorMode, user, ...globalLogin }
}
