//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { plansColor } from '@/config/plans'
export default defineComponent({
  props: {
    plan: String,
  },
  setup(props) {
    return { props, plansColor }
  },
})
