import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import EmailContentPlaceholderComponent from './EmailContentPlaceholderComponent.vue'

export default Node.create({
  name: 'emailContentPlaceholder',
  attrs: {
    field: {
      default: null,
    },
  },
  atom: true,
  selectable: false,
  isolating: true,
  group: 'inline',
  content: 'inline*',
  inline: true,
  draggable: true,

  parseHTML() {
    return [
      {
        tag: 'span[data-type="email-content-placeholder"]',
      },
    ]
  },

  addAttributes() {
    return {
      field: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-field'),
        renderHTML: (attributes) => {
          return {
            'data-field': attributes.field,
          }
        },
      },
    }
  },

  commands() {},

  renderHTML(attributes) {
    return [
      'span',
      mergeAttributes(attributes.HTMLAttributes, {
        'data-type': 'email-content-placeholder',
      }),
      0,
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(EmailContentPlaceholderComponent)
  },
})
