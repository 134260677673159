/* Used to fix viewport heights on mobile devices */

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

let handleChange = () => {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, 0)
}

window.addEventListener('resize', handleChange)
window.addEventListener('orientationchange', handleChange)

try {
  let portrait = window.matchMedia('(orientation: portrait)')
  if (portrait && typeof portrait.addEventListener === 'function') {
    portrait.addEventListener('change', handleChange)
  }
} catch (err) {}
