//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has as _has, get as _get } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext, ref } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const modal = useModal()

    const { $bus, $toast, $projectsRepository, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const deleteButton = ref(null)
    const error = ref(null)

    const deleteItem = async () => {
      emit('delete')

      try {
        setLoading(true, 'delete_item')

        const { data } = await $projectsRepository.delete(props.project.id, 'planning')

        if (data.success === true || data.deleted === true) {
          await modal.closeModal()
          $toast.show(app.i18n.t(`planning.notice.deleted`))
          $bus.emit(`delete.item`, { object: props.project, type: 'planning' })
          emit('deleted')
        } else {
          error.value = data.error || 'unknown'
        }
      } catch (err) {
        error.value = _get(err.response, 'data.data.error', null)
        setErrorState('delete_item')
      } finally {
        setLoading(false, 'delete_item')
      }
    }

    return {
      props,
      ...modal,
      isLoading,
      hasErrorState,
      error,
      deleteButton,
      deleteItem,
    }
  },
})
