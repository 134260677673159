//
//
//
//
//
//

import useCurrencyInput from 'vue-currency-input'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { getCountryLocale } from '@/config/locales'
import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    value: {
      default: null,
    },
    modelValue: {
      default: null,
    },
    context: {
      type: Object,
      required: true,
    },
    label: {
      type: [Boolean, String],
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        distractionFree: true,
        allowNegative: false,
        autoDecimalDigits: false,
      }),
    },
  },
  setup(props) {
    const { company } = useUser()

    const inputOptions = computed(() => {
      return {
        ...{
          currency: company.value.currency || 'EUR',
          locale: getCountryLocale(company.value.country),
          distractionFree: true,
          allowNegative: false,
          autoDecimalDigits: false,
        },
        ...props.options,
      }
    })

    async function handleBlur() {
      props.context.performValidation()
      props.context.blurHandler()
      await props.context.getValidationErrors()
    }

    return { props, inputOptions, handleBlur }
  },
})
