var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(_vm.item.route ? 'NuxtLink' : 'button',{tag:"component",staticClass:"w-full relative group flex items-center px-3 py-1 text-sm leading-5 focus:outline-none rounded-lg",class:( _obj = {}, _obj[_vm.item.class] = true, _obj['text-brand-500 bg-brand-50   font-medium z-50'] =  _vm.currentNav === _vm.item.key, _obj['text-gray-600  hover:text-gray-700 hover:bg-gray-50'] =  _vm.currentNav !== _vm.item.key, _obj ),style:({
      //boxShadow: currentNav === item.key ? '0 0 24px 0 rgba(51, 65, 85, .075)' : 'none',
    }),attrs:{"to":_vm.item.route || null},on:{"click":function($event){_vm.item.click ? _vm.item.click(_vm.$bus) : null}},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"w-8 text-base text-center mr-1.5 -ml-1.5"},[(_vm.item.icon)?_c('fa-icon',{staticClass:"!text-lg",style:({
          '--fa-primary-color': _vm.currentNav === _vm.item.key ? '#6366f1' : '#999',
          '--fa-secondary-color': _vm.currentNav === _vm.item.key ? '#6366f1' : '#888',
        }),attrs:{"icon":_vm.item.icon}}):_vm._e()],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(("layout.navigation." + (_vm.item.key)))))]),_vm._v(" "),(_vm.item.new)?_c('div',{staticClass:"ml-auto -mr-1.5 text-[10px]"},[_c('span',{staticClass:"bg-[conic-gradient(at_top_right,_var(--tw-gradient-stops))] from-pink-500 via-red-500 to-yellow-500 text-white font-medium rounded-full px-2 py-0.5"},[_vm._v("Nouveau")])]):(_vm.item.beta)?_c('BetaBadge',{staticClass:"ml-auto"}):_vm._e()],1),_vm._v(" "),(_vm.item.items && (_vm.hasCurrentChild || _vm.currentNav === _vm.item.key))?_c('div',{staticClass:"pl-3"},_vm._l((_vm.item.items),function(subItem){return _c('SidebarItem',{key:("sidebar_item_" + (_vm.item.key) + "_" + (subItem.key)),attrs:{"item":subItem},on:{"close":function($event){return _vm.$emit('close')}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }