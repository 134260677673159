//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has } from 'lodash-es'
import { computed, defineComponent, inject, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { route } = useContext()
    const sidebarMap = inject('sidebarMap')

    const currentNav = computed(() => {
      return route.value.matched.map((r) => {
        return r.components.default.options ? r.components.default.options.currentNav : r.components.default.currentNav
      })[0]
    })

    const invert = (obj) => Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: a })))

    const hasCurrentChild = computed(() => {
      if (!_has(props.item, 'items')) {
        return false
      }

      return Object.values(props.item.items).reduce((bool, item) => {
        const currentChild = _get(sidebarMap.value, item.key, null)
        if (currentChild && currentNav.value === item.key) {
          return true
        }
        return bool
      }, false)
    })

    return { props, currentNav, hasCurrentChild }
  },
})
