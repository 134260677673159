//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import { has as _has } from 'lodash-es'

const sizes = {
  xl: 'px-5 py-3 text-base',
  large: 'p-2 px-3 sm:p-3 text-sm',
  default: 'py-2 px-3 text-sm',
  small: 'py-1.5 px-2 text-sm',
  xs: 'py-1 px-2 text-xs',
}

const iconContainerSizes = {
  xl: 'w-5',
  large: 'w-4',
  default: 'w-4',
  small: 'w-4',
  xs: 'w-3',
}

const minimalSizes = {
  large: 'p-3 text-sm',
  default: 'py-2 px-2 text-sm',
  small: 'py-1 px-1 text-sm',
  xs: 'py-0.5 px-1 text-xs',
}

export default defineComponent({
  props: {
    submit: Boolean,
    size: {
      type: String,
      default: 'default',
    },
    iconBefore: {
      type: Array,
      default: () => [],
    },
    iconBeforeClass: {
      type: String,
      default: 'mr-2',
    },
    iconAfter: {
      type: Array,
      default: () => [],
    },
    iconAfterClass: {
      type: String,
      default: 'ml-2',
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '',
    },
    external: Boolean,
    loading: [Object, Boolean],
    error: [Object, Boolean],
    outline: Boolean,
    light: Boolean,
    minimal: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      default: 'gray',
    },
  },

  setup(props, { attrs }) {
    const buttonType = computed(() => (props.submit ? 'submit' : 'button'))

    const getClasses = computed(() => {
      let classes = []
      if (props.minimal) {
        classes.push(`font-normal btn-minimal ${minimalSizes[props.size]}`)
      } else {
        classes.push(sizes[props.size])
      }

      if (props.outline) {
        classes.push('btn-outline')
      }

      if (!props.error) {
        classes.push(
          {
            brand: 'btn-brand',
            yellow: 'btn-yellow',
            darkyellow: 'btn-darkyellow',
            blue: 'btn-blue',
            gray: 'btn-gray',
            lightgray: 'btn-lightgray',
            darkgray: 'btn-darkgray',
            red: 'btn-red',
            green: 'btn-green',
            orange: 'btn-orange',
          }[props.color]
        )
      } else {
        classes.push('btn-red')
      }

      if (props.disabled) {
        classes.push('')
      }

      if (!props.light) {
        classes.push('font-medium')
      }

      classes.push(attrs.class)
      return classes.join(' ')
    })

    const iconBeforeProps = computed(() =>
      props.loading ? ['far', 'spinner-third'] : props.error ? ['far', 'triangle-exclamation'] : props.iconBefore
    )
    const iconAfterProps = computed(() => (props.loading ? ['far', 'spinner-third'] : props.iconAfter))

    const isLoading = computed(() => {
      if (_has(props.loading, 'value')) {
        return props.loading.value
      }
      return props.loading
    })
    const hasError = computed(() => {
      if (_has(props.error, 'value')) {
        return props.error.value
      }
      return props.error
    })

    const iconContainerClass = computed(() => iconContainerSizes[props.size])

    return { buttonType, getClasses, iconBeforeProps, iconAfterProps, iconContainerClass, isLoading, hasError }
  },
})
