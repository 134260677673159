//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import {
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CREDIT,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_LOAN,
  PAYMENT_METHOD_DISCOUNT,
  PAYMENT_METHOD_WIRE,
} from '@/constants/documents'
export default defineComponent({
  props: {
    payment: Object,
  },
  setup(props) {
    const colors = {
      [PAYMENT_METHOD_CARD]: 'bg-blue-100 text-blue-500',
      [PAYMENT_METHOD_CASH]: 'bg-green-100 text-green-500',
      [PAYMENT_METHOD_WIRE]: 'bg-orange-100 text-orange-500',
      [PAYMENT_METHOD_LOAN]: 'bg-orange-100 text-orange-500',
      [PAYMENT_METHOD_DIRECT_DEBIT]: 'bg-orange-100 text-orange-500',
      [PAYMENT_METHOD_CHECK]: 'bg-yellow-100 text-yellow-600',
      [PAYMENT_METHOD_CREDIT]: 'bg-cyan-100 text-cyan-600',
      [PAYMENT_METHOD_DISCOUNT]: 'bg-indigo-100 text-indigo-600',
      onlinePayment: 'bg-green-100 text-green-500',
    }

    const paymentType = computed(() =>
      props.payment.processor === 'bridge' ? 'onlinePayment' : props.payment.paymentMethod
    )

    return { props, paymentType, colors }
  },
})
