//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STATUS_PAID, STATUS_RELEASED, STATUS_ON_HOLD, STATUS_CANCELLED } from '@/constants/holdbacks'
import useModal from '@/composables/modal'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  provide,
  ref,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'
import Teleport from 'vue2-teleport'

import { useStatus } from '@/composables/document'

export default defineComponent({
  components: {
    Teleport,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { $bus } = useContext()

    const modal = useModal()

    const { loaders, isLoading, setLoading } = useLoading()
    const holdback = ref(null)

    const { $holdbacksRepository } = useContext()

    const { fetch, fetchState } = useFetch(async () => {
      if (modal.isModalOpen()) {
        const { data } = await $holdbacksRepository.find(props.id)
        holdback.value = data
      }
    })

    const { setStatus } = useStatus($holdbacksRepository)

    const changeStatus = async function (status) {
      try {
        setLoading(true, `set_status_${status}`)
        await setStatus(holdback.value, status)

        $bus.emit('update.item', {
          type: 'holdback',
          data: { ...holdback.value, status },
        })

        holdback.value.status = status
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false, `set_status_${status}`)
      }
    }

    watch(
      () => modal.isModalOpen(),
      (val) => val && fetch()
    )

    onMounted(() => {
      $bus.on('update.item', ({ type, data }) => {
        if (data.id === holdback.value.id) {
          holdback.value = { ...data }
        }
      })
    })

    onUnmounted(() => {
      $bus.off('update.item')
    })

    return {
      ...modal,
      loaders,
      isLoading,
      props,
      holdback,
      fetchState,
      changeStatus,
      STATUS_PAID,
      STATUS_ON_HOLD,
      STATUS_RELEASED,
      STATUS_CANCELLED,
    }
  },
})
