export const PROJECT_STATUS_PENDING = 'pending'
export const PROJECT_STATUS_PLANNED = 'planned'
export const PROJECT_STATUS_IN_PROGRESS = 'in_progress'
export const PROJECT_STATUS_COMPLETED = 'completed'
export const PROJECT_STATUS_LOST = 'lost'
export const PROJECT_STATUS_DISPUTE = 'dispute'

export const PROJECT_STATUSES = [
  PROJECT_STATUS_PENDING,
  //PROJECT_STATUS_PLANNED,
  PROJECT_STATUS_IN_PROGRESS,
  PROJECT_STATUS_COMPLETED,
  PROJECT_STATUS_LOST,
  PROJECT_STATUS_DISPUTE,
]

export const COLOR_BLUE = 'blue'
export const COLOR_PURPLE = 'purple'
export const COLOR_GREEN = 'green'
export const COLOR_ORANGE = 'orange'
export const COLOR_YELLOW = 'yellow'
export const COLOR_PINK = 'pink'
export const COLOR_GREY = 'grey'

export const PROJECT_COLORS = {
  [COLOR_BLUE]: {
    background: '#C9D5F9',
    border: '#bcccff',
    color: '#444',
  },
  [COLOR_PURPLE]: {
    background: '#D5C8FB',
    border: '#c6b3ff',
    color: '#444',
  },
  [COLOR_GREEN]: {
    background: '#CEF6E9',
    border: '#a2ecd5',
    color: '#219e76',
  },
  [COLOR_ORANGE]: {
    background: '#FFD6BC',
    border: '#fdc5a2',
    color: '#444',
  },
  [COLOR_YELLOW]: {
    background: '#F4F8AC',
    border: '#e3e97d',
    color: '#444',
  },
  [COLOR_PINK]: {
    background: '#FFD5E3',
    border: '#ffbcd2',
    color: '#444',
  },
  [COLOR_GREY]: {
    background: '#EBEBE8',
    border: '#d7d7d2',
    color: '#444',
  },
}
