import { render, staticRenderFns } from "./HelpSidePanel.vue?vue&type=template&id=6290032c&"
import script from "./HelpSidePanel.vue?vue&type=script&lang=js&"
export * from "./HelpSidePanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,FeedbackModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/FeedbackModal.vue').default,SidePanel: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/SidePanel.vue').default})
