const countriesList = {
  af: 'Afghanistan',
  za: 'Afrique du Sud',
  al: 'Albanie',
  dz: 'Alg\u00e9rie',
  de: 'Allemagne',
  ad: 'Andorre',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctique',
  ag: 'Antigua-et-Barbuda',
  sa: 'Arabie saoudite',
  ar: 'Argentine',
  am: 'Arm\u00e9nie',
  aw: 'Aruba',
  au: 'Australie',
  at: 'Autriche',
  az: 'Azerba\u00efdjan',
  bs: 'Bahamas',
  bh: 'Bahre\u00efn',
  bd: 'Bangladesh',
  bb: 'Barbade',
  be: 'Belgique',
  bz: 'Belize',
  bj: 'B\u00e9nin',
  bm: 'Bermudes',
  bt: 'Bhoutan',
  by: 'Bi\u00e9lorussie',
  bo: 'Bolivie',
  ba: 'Bosnie-Herz\u00e9govine',
  bw: 'Botswana',
  br: 'Br\u00e9sil',
  bn: 'Brun\u00e9i Darussalam',
  bg: 'Bulgarie',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodge',
  cm: 'Cameroun',
  ca: 'Canada',
  cv: 'Cap-Vert',
  ea: 'Ceuta et Melilla',
  cl: 'Chili',
  cn: 'Chine',
  cy: 'Chypre',
  co: 'Colombie',
  km: 'Comores',
  cg: 'Congo-Brazzaville',
  cd: 'Congo-Kinshasa',
  kp: 'Cor\u00e9e du Nord',
  kr: 'Cor\u00e9e du Sud',
  cr: 'Costa Rica',
  ci: 'C\u00f4te d\u2019Ivoire',
  hr: 'Croatie',
  cu: 'Cuba',
  cw: 'Cura\u00e7ao',
  dk: 'Danemark',
  dg: 'Diego Garcia',
  dj: 'Djibouti',
  dm: 'Dominique',
  eg: '\u00c9gypte',
  ae: '\u00c9mirats arabes unis',
  ec: '\u00c9quateur',
  er: '\u00c9rythr\u00e9e',
  es: 'Espagne',
  ee: 'Estonie',
  sz: 'Eswatini',
  va: '\u00c9tat de la Cit\u00e9 du Vatican',
  fm: '\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie',
  us: '\u00c9tats-Unis',
  et: '\u00c9thiopie',
  fj: 'Fidji',
  fi: 'Finlande',
  fr: 'France',
  ga: 'Gabon',
  gm: 'Gambie',
  ge: 'G\u00e9orgie',
  gs: 'G\u00e9orgie du Sud et \u00eeles Sandwich du Sud',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gr: 'Gr\u00e8ce',
  gd: 'Grenade',
  gl: 'Groenland',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gg: 'Guernesey',
  gn: 'Guin\u00e9e',
  gq: 'Guin\u00e9e \u00e9quatoriale',
  gw: 'Guin\u00e9e-Bissau',
  gy: 'Guyana',
  gf: 'Guyane',
  ht: 'Ha\u00efti',
  hn: 'Honduras',
  hu: 'Hongrie',
  cx: '\u00cele Christmas',
  ac: '\u00cele de l\u2019Ascension',
  im: '\u00cele de Man',
  nf: '\u00cele Norfolk',
  ax: '\u00celes \u00c5land',
  ky: '\u00celes Ca\u00efmans',
  ic: '\u00celes Canaries',
  cc: '\u00celes Cocos',
  ck: '\u00celes Cook',
  fo: '\u00celes F\u00e9ro\u00e9',
  fk: '\u00celes Malouines',
  mp: '\u00celes Mariannes du Nord',
  mh: '\u00celes Marshall',
  um: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
  pn: '\u00celes Pitcairn',
  sb: '\u00celes Salomon',
  tc: '\u00celes Turques-et-Ca\u00efques',
  vg: '\u00celes Vierges britanniques',
  vi: '\u00celes Vierges des \u00c9tats-Unis',
  in: 'Inde',
  id: 'Indon\u00e9sie',
  iq: 'Irak',
  ir: 'Iran',
  ie: 'Irlande',
  is: 'Islande',
  il: 'Isra\u00ebl',
  it: 'Italie',
  jm: 'Jama\u00efque',
  jp: 'Japon',
  je: 'Jersey',
  jo: 'Jordanie',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  kg: 'Kirghizistan',
  ki: 'Kiribati',
  xk: 'Kosovo',
  kw: 'Kowe\u00eft',
  re: 'La R\u00e9union',
  la: 'Laos',
  ls: 'Lesotho',
  lv: 'Lettonie',
  lb: 'Liban',
  lr: 'Lib\u00e9ria',
  ly: 'Libye',
  li: 'Liechtenstein',
  lt: 'Lituanie',
  lu: 'Luxembourg',
  mk: 'Mac\u00e9doine',
  mg: 'Madagascar',
  my: 'Malaisie',
  mw: 'Malawi',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malte',
  ma: 'Maroc',
  mq: 'Martinique',
  mu: 'Maurice',
  mr: 'Mauritanie',
  yt: 'Mayotte',
  mx: 'Mexique',
  md: 'Moldavie',
  mc: 'Monaco',
  mn: 'Mongolie',
  me: 'Mont\u00e9n\u00e9gro',
  ms: 'Montserrat',
  mz: 'Mozambique',
  mm: 'Myanmar (Birmanie)',
  na: 'Namibie',
  nr: 'Nauru',
  np: 'N\u00e9pal',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nig\u00e9ria',
  nu: 'Niue',
  no: 'Norv\u00e8ge',
  nc: 'Nouvelle-Cal\u00e9donie',
  nz: 'Nouvelle-Z\u00e9lande',
  om: 'Oman',
  ug: 'Ouganda',
  uz: 'Ouzb\u00e9kistan',
  pk: 'Pakistan',
  pw: 'Palaos',
  pa: 'Panama',
  pg: 'Papouasie-Nouvelle-Guin\u00e9e',
  py: 'Paraguay',
  nl: 'Pays-Bas',
  bq: 'Pays-Bas carib\u00e9ens',
  pe: 'P\u00e9rou',
  ph: 'Philippines',
  pl: 'Pologne',
  pf: 'Polyn\u00e9sie fran\u00e7aise',
  pr: 'Porto Rico',
  pt: 'Portugal',
  qa: 'Qatar',
  hk: 'R.A.S. chinoise de Hong Kong',
  mo: 'R.A.S. chinoise de Macao',
  cf: 'R\u00e9publique centrafricaine',
  do: 'R\u00e9publique dominicaine',
  ro: 'Roumanie',
  gb: 'Royaume-Uni',
  ru: 'Russie',
  rw: 'Rwanda',
  eh: 'Sahara occidental',
  bl: 'Saint-Barth\u00e9lemy',
  kn: 'Saint-Christophe-et-Ni\u00e9v\u00e8s',
  sm: 'Saint-Marin',
  mf: 'Saint-Martin',
  sx: 'Saint-Martin (partie n\u00e9erlandaise)',
  pm: 'Saint-Pierre-et-Miquelon',
  vc: 'Saint-Vincent-et-les-Grenadines',
  sh: 'Sainte-H\u00e9l\u00e8ne',
  lc: 'Sainte-Lucie',
  sv: 'Salvador',
  ws: 'Samoa',
  as: 'Samoa am\u00e9ricaines',
  st: 'Sao Tom\u00e9-et-Principe',
  sn: 'S\u00e9n\u00e9gal',
  rs: 'Serbie',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapour',
  sk: 'Slovaquie',
  si: 'Slov\u00e9nie',
  so: 'Somalie',
  sd: 'Soudan',
  ss: 'Soudan du Sud',
  lk: 'Sri Lanka',
  se: 'Su\u00e8de',
  ch: 'Suisse',
  sr: 'Suriname',
  sj: 'Svalbard et Jan Mayen',
  sy: 'Syrie',
  tj: 'Tadjikistan',
  tw: 'Ta\u00efwan',
  tz: 'Tanzanie',
  td: 'Tchad',
  cz: 'Tch\u00e9quie',
  tf: 'Terres australes fran\u00e7aises',
  io: 'Territoire britannique de l\u2019oc\u00e9an Indien',
  ps: 'Territoires palestiniens',
  th: 'Tha\u00eflande',
  tl: 'Timor oriental',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinit\u00e9-et-Tobago',
  ta: 'Tristan da Cunha',
  tn: 'Tunisie',
  tm: 'Turkm\u00e9nistan',
  tr: 'Turquie',
  tv: 'Tuvalu',
  ua: 'Ukraine',
  uy: 'Uruguay',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Vietnam',
  wf: 'Wallis-et-Futuna',
  ye: 'Y\u00e9men',
  zm: 'Zambie',
  zw: 'Zimbabwe',
}

const commonCountries = {
  fr: 'France',
  gp: 'Guadeloupe',
  gf: 'Guyane',
  be: 'Belgique',
  ch: 'Suisse',
  lu: 'Luxembourg',
}

const openCountries = ['fr', 'gp', 'gf', 're', 'mq', 'nc', 'be', 'ch', 'lu', 'ci', 'bj', 'cg', 'cd', 'ma', 'dz', 'sn']

const vatRestrictedCountries = [
  'fr',
  'gp', // Guadeloupe
  'mq', // Martinique
  're', // La Réunion
  'gf', // Guyane française
  'yt', // Mayotte
  'mf', // Saint-Martin
  'bl', // Saint-Barthélemy
  'nc', // Nouvelle-Calédonie
  'pf', // Polynésie française
  'wf', // Wallis et Futuna
  'pm', // Saint-Pierre et Miquelon
  'tf', // Terres australes et antarctiques françaises
]

const frDomCountries = [
  'fr', // France
  'gp', // Guadeloupe
  'mq', // Martinique
  're', // La Réunion
  'gf', // Guyane française
  'yt', // Mayotte
  'mf', // Saint-Martin
  'bl', // Saint-Barthélemy
]

const openCountriesMeta = {
  gp: {
    listPrefix: `${countriesList.fr} > `,
  },
  gf: {
    listPrefix: `${countriesList.fr} > `,
  },
  re: {
    listPrefix: `${countriesList.fr} > `,
  },
  mq: {
    listPrefix: `${countriesList.fr} > `,
  },
  nc: {
    listPrefix: `${countriesList.fr} > `,
  },
}

// Used instead of Intl.supportedValuesOf() for compatibility, switch later when usage % improves
const timezones = [
  'Africa/Abidjan',
  'Africa/Accra',
  'Africa/Addis_Ababa',
  'Africa/Algiers',
  'Africa/Asmera',
  'Africa/Bamako',
  'Africa/Bangui',
  'Africa/Banjul',
  'Africa/Bissau',
  'Africa/Blantyre',
  'Africa/Brazzaville',
  'Africa/Bujumbura',
  'Africa/Cairo',
  'Africa/Casablanca',
  'Africa/Ceuta',
  'Africa/Conakry',
  'Africa/Dakar',
  'Africa/Dar_es_Salaam',
  'Africa/Djibouti',
  'Africa/Douala',
  'Africa/El_Aaiun',
  'Africa/Freetown',
  'Africa/Gaborone',
  'Africa/Harare',
  'Africa/Johannesburg',
  'Africa/Juba',
  'Africa/Kampala',
  'Africa/Khartoum',
  'Africa/Kigali',
  'Africa/Kinshasa',
  'Africa/Lagos',
  'Africa/Libreville',
  'Africa/Lome',
  'Africa/Luanda',
  'Africa/Lubumbashi',
  'Africa/Lusaka',
  'Africa/Malabo',
  'Africa/Maputo',
  'Africa/Maseru',
  'Africa/Mbabane',
  'Africa/Mogadishu',
  'Africa/Monrovia',
  'Africa/Nairobi',
  'Africa/Ndjamena',
  'Africa/Niamey',
  'Africa/Nouakchott',
  'Africa/Ouagadougou',
  'Africa/Porto-Novo',
  'Africa/Sao_Tome',
  'Africa/Tripoli',
  'Africa/Tunis',
  'Africa/Windhoek',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/La_Rioja',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Fort_Nelson',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Monticello',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port-au-Prince',
  'America/Port_of_Spain',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Punta_Arenas',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/McMurdo',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'Arctic/Longyearbyen',
  'Asia/Aden',
  'Asia/Almaty',
  'Asia/Amman',
  'Asia/Anadyr',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Ashgabat',
  'Asia/Atyrau',
  'Asia/Baghdad',
  'Asia/Bahrain',
  'Asia/Baku',
  'Asia/Bangkok',
  'Asia/Barnaul',
  'Asia/Beirut',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Chita',
  'Asia/Choibalsan',
  'Asia/Colombo',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dili',
  'Asia/Dubai',
  'Asia/Dushanbe',
  'Asia/Famagusta',
  'Asia/Gaza',
  'Asia/Hebron',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jayapura',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Katmandu',
  'Asia/Khandyga',
  'Asia/Krasnoyarsk',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Asia/Kuwait',
  'Asia/Macau',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Muscat',
  'Asia/Nicosia',
  'Asia/Novokuznetsk',
  'Asia/Novosibirsk',
  'Asia/Omsk',
  'Asia/Oral',
  'Asia/Phnom_Penh',
  'Asia/Pontianak',
  'Asia/Pyongyang',
  'Asia/Qatar',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Asia/Rangoon',
  'Asia/Riyadh',
  'Asia/Saigon',
  'Asia/Sakhalin',
  'Asia/Samarkand',
  'Asia/Seoul',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Srednekolymsk',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Thimphu',
  'Asia/Tokyo',
  'Asia/Tomsk',
  'Asia/Ulaanbaatar',
  'Asia/Urumqi',
  'Asia/Ust-Nera',
  'Asia/Vientiane',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Bermuda',
  'Atlantic/Canary',
  'Atlantic/Cape_Verde',
  'Atlantic/Faeroe',
  'Atlantic/Madeira',
  'Atlantic/Reykjavik',
  'Atlantic/South_Georgia',
  'Atlantic/St_Helena',
  'Atlantic/Stanley',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Broken_Hill',
  'Australia/Currie',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Hobart',
  'Australia/Lindeman',
  'Australia/Lord_Howe',
  'Australia/Melbourne',
  'Australia/Perth',
  'Australia/Sydney',
  'Europe/Amsterdam',
  'Europe/Andorra',
  'Europe/Astrakhan',
  'Europe/Athens',
  'Europe/Belgrade',
  'Europe/Berlin',
  'Europe/Bratislava',
  'Europe/Brussels',
  'Europe/Bucharest',
  'Europe/Budapest',
  'Europe/Busingen',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Dublin',
  'Europe/Gibraltar',
  'Europe/Guernsey',
  'Europe/Helsinki',
  'Europe/Isle_of_Man',
  'Europe/Istanbul',
  'Europe/Jersey',
  'Europe/Kaliningrad',
  'Europe/Kiev',
  'Europe/Kirov',
  'Europe/Lisbon',
  'Europe/Ljubljana',
  'Europe/London',
  'Europe/Luxembourg',
  'Europe/Madrid',
  'Europe/Malta',
  'Europe/Mariehamn',
  'Europe/Minsk',
  'Europe/Monaco',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Podgorica',
  'Europe/Prague',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Samara',
  'Europe/San_Marino',
  'Europe/Sarajevo',
  'Europe/Saratov',
  'Europe/Simferopol',
  'Europe/Skopje',
  'Europe/Sofia',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Tirane',
  'Europe/Ulyanovsk',
  'Europe/Uzhgorod',
  'Europe/Vaduz',
  'Europe/Vatican',
  'Europe/Vienna',
  'Europe/Vilnius',
  'Europe/Volgograd',
  'Europe/Warsaw',
  'Europe/Zagreb',
  'Europe/Zaporozhye',
  'Europe/Zurich',
  'Indian/Antananarivo',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Comoro',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Mayotte',
  'Indian/Reunion',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Bougainville',
  'Pacific/Chatham',
  'Pacific/Easter',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Fiji',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Gambier',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Honolulu',
  'Pacific/Johnston',
  'Pacific/Kiritimati',
  'Pacific/Kosrae',
  'Pacific/Kwajalein',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Midway',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Palau',
  'Pacific/Pitcairn',
  'Pacific/Ponape',
  'Pacific/Port_Moresby',
  'Pacific/Rarotonga',
  'Pacific/Saipan',
  'Pacific/Tahiti',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
]

export {
  countriesList,
  vatRestrictedCountries,
  frDomCountries,
  commonCountries,
  openCountries,
  openCountriesMeta,
  timezones,
}
