// Default document text color: #555
export const colors = [
  /* '#f6c3c7',
  '#ffecc7',
  '#fbf8c7',
  '#f1f1c8',
  '#d8ead0',
  '#cae6ef',
  '#c6e0f7',
  '#cdc6e4',
  '#e1c3df',
  '#f2bfdc',*/
  '#f18482',
  '#fdd47b',
  '#f9ef83',
  '#e6e77e',
  '#aad29e',
  '#8acdd7',
  '#84c4ec',
  '#8f94ea',
  '#a776b4',
  '#df7db3',
  '#e94340',
  '#f5962f',
  '#faea30',
  '#d0da30',
  '#7bbc62',
  '#50bec7',
  '#4889c5',
  '#45519f',
  '#854b98',
  '#d71e83',
  '#cb1517',
  '#c7780f',
  '#c9b604',
  '#adb717',
  '#3fa636',
  '#189397',
  '#2566b2',
  '#333e93',
  '#71368c',
  '#a91056',
  '#8a1713',
  '#8d4b16',
  '#8e811c',
  '#798126',
  '#257c35',
  '#086a74',
  '#084791',
  '#2c2f79',
  '#622681',
  '#73103f',
]

export const projectColors = [
  '#f18482',
  '#fdd47b',
  '#f9ef83',
  '#e6e77e',
  '#aad29e',
  '#8acdd7',
  '#84c4ec',
  '#8f94ea',
  '#a776b4',

  '#999999',
  '#e94340',
  '#f5962f',
  '#faea30',
  '#d0da30',
  '#7bbc62',
  '#50bec7',
  '#4889c5',
  '#45519f',
  '#854b98',

  '#666666',
  '#8a1713',
  '#8d4b16',
  '#8e811c',
  '#798126',
  '#257c35',
  '#086a74',
  '#084791',
  '#2c2f79',
  '#622681',

  '#444444',
]

export const grays = ['#333333', '#444444', '#555555', '#666666', '#888888', '#bbbbbb']

export const nameToHex = (color) => {
  if (!color) return color
  if (typeof color === 'string' && color.trim() === '') return color
  if (Array.isArray(color)) return color

  const colors = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    indianred: '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
  }

  if (typeof colors[color.toLowerCase()] != 'undefined') return colors[color.toLowerCase()]

  return color
}

export const nameToRgb = (color) => hexToRgb(nameToHex(color), true)

export const anyToRgb = (color) => {
  let fromName = nameToHex(color)
  if (fromName !== color) {
    let fromRgb = hexToRgb(fromName)
    if (fromRgb) return fromRgb
  }

  if (!color) return null

  if (color.match(/^rgb/)) {
    let c = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
    if (!c) return null
    return [parseInt(c[1]), parseInt(c[2]), parseInt(c[3])]
  }

  let fromRgb = hexToRgb(color)
  if (fromRgb) return fromRgb

  return null
}

export const anyToHex = (color) => {
  const rgb = anyToRgb(color)
  if (!rgb) return null
  return rgbToHex(rgb)
}

export const rgbToHex = ([r, g, b]) => {
  r = parseInt(r).toString(16)
  g = parseInt(g).toString(16)
  b = parseInt(b).toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r + g + b
}

export const hexToRgb = (hex, string = false) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result) {
    return string
      ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`
      : [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
  }
  return null
}

// Distance between 2 colors (in RGB)
// https://stackoverflow.com/questions/23990802/find-nearest-color-from-a-colors-list
function distance(a, b) {
  return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2) + Math.pow(a[2] - b[2], 2))
}

export const rgbToHsl = (rgb) => {
  const r = rgb[0] / 255
  const g = rgb[1] / 255
  const b = rgb[2] / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const diff = max - min
  const add = max + min

  const hue =
    min === max
      ? 0
      : r === max
      ? ((60 * (g - b)) / diff + 360) % 360
      : g === max
      ? (60 * (b - r)) / diff + 120
      : (60 * (r - g)) / diff + 240

  const lum = 0.5 * add

  const sat = lum === 0 ? 0 : lum === 1 ? 1 : lum <= 0.5 ? diff / add : diff / (2 - add)

  const h = Math.round(hue)
  const s = Math.round(sat * 100)
  const l = Math.round(lum * 100)
  const a = rgb[3] || 1

  return [h, s, l, a]
}

export const hexToHsl = (color) => {
  const rgb = hexToRgb(color)
  const hsl = rgbToHsl(rgb)
  return `original: ${color} - H: ${hsl[0]} S: ${hsl[1]} L: ${hsl[2]}`
}

// return nearest color from array
export const nearestColor = (rgb, colors) => {
  let lowest = Number.POSITIVE_INFINITY
  let tmp
  let index = 0
  colors.forEach((el, i) => {
    tmp = distance(rgb, hexToRgb(el))
    if (tmp < lowest) {
      lowest = tmp
      index = i
    }
  })
  return colors[index]
}

export const averageDelta = ([x, ...xs]) => {
  if (x === undefined) return 0
  else return xs.reduce(([acc, last], x) => [acc + (x - last), x], [0, x])[0] / xs.length
}

export const nearestGray = (rgb) => {
  let lowest = Number.POSITIVE_INFINITY
  let tmp
  let index = 0
  const grayColors = [].concat(grays, ['#000000'])
  grayColors.forEach((el, i) => {
    tmp = distance(rgb, hexToRgb(el))
    if (tmp < lowest) {
      lowest = tmp
      index = i
    }
  })
  return grayColors[index]
}

// Gets color brightness from http://alienryderflex.com/hsp.html
export const getBrightness = (color) => {
  if (!color) return 0

  let c, r, g, b

  if (!Array.isArray(color)) {
    if (color.match(/^rgb/)) {
      // If RGB, store values in separate variables
      c = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
      r = c[1]
      g = c[2]
      b = c[3]
    } else {
      // If hex, convert to RGB: http://gist.github.com/983661
      c = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = c >> 16
      g = (c >> 8) & 255
      b = c & 255
    }
  } else {
    r = color[0]
    g = color[1]
    b = color[2]
  }

  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp
}

export const isLight = (color) => getBrightness(color) > 127.5
export const isTooLight = (color) => getBrightness(color) > 180
