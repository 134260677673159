var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Teleport',{attrs:{"to":"#modals"}},[_c('transition',{attrs:{"appear":"","name":"modal"}},[(_vm.isOpen)?_c('div',{staticClass:"zIndexMod modal fixed top-0 inset-x-0 pb-4 right-0 left-0 bottom-0",class:( _obj = {
        'px-4': !_vm.fullScreen
      }, _obj[_vm.zIndex] = true, _obj ),style:(_vm.zIndexNumber ? ("z-index: " + _vm.zIndexNumber + " !important") : '')},[_c('div',{staticClass:"absolute top-0 right-0 left-0 bottom-0 flex justify-center backdrop-blur-[2px]",class:{
          'items-start': !_vm.centered,
          'items-center': _vm.centered,
        }},[_c('div',{staticClass:"modal-overlay fixed top-0 right-0 left-0 bottom-0 transition-opacity"},[_c('div',{staticClass:"absolute top-0 right-0 left-0 bottom-0",class:[_vm.overlayClass],on:{"click":function($event){return _vm.$emit('closeModal')}}})]),_vm._v(" "),_c('div',{ref:"modalContent",staticClass:"modal-content relative transition-all w-screen vh-full"},[_c('simplebar',{staticClass:"h-full",attrs:{"options":{ disableScrollX: true }}},[_c('div',{class:[_vm.fullScreen ? '' : 'p-4 sm:p-0'].concat([_vm.contentClasses])},[_vm._t("default")],2),_vm._v(" "),(!_vm.fullScreen)?_c('div',{staticClass:"h-10"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"absolute w-screen vh-full top-0 right-0 left-0 bottom-0",on:{"click":function($event){return _vm.$emit('closeModal')}}})])],1)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }