//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, ref } from '@nuxtjs/composition-api'

import { useLoading } from '@/composables/loading'
import { useUser } from '@/composables/user'

export default defineComponent({
  setup() {
    const { app } = useContext()
    const { setLoading, isLoading } = useLoading()
    const { user, updateUser } = useUser()

    const currentNews = ref(null)

    const { $newsRepository } = useContext()
    const news = ref([])

    const fetchNews = async () => {
      setLoading(true, 'news')
      try {
        const { data } = await $newsRepository.list({
          params: { _order: 'desc', _sort: 'createdAt', _limit: 50 },
        })
        news.value = data
      } catch (err) {
      } finally {
        setLoading(false, 'news')
      }
    }

    const updateReadStatus = () => {
      updateUser({ newsReadAt: new Date(), newsCount: 0 })
    }

    return { app, user, news, currentNews, fetchNews, updateReadStatus, isLoading }
  },
})
