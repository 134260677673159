//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import { useUser } from '@/composables/user'
export default defineComponent({
  props: {
    feature: String,
    wrapperClass: String,
    innerClass: String,
  },
  setup(props) {
    const { can, requiredPlan } = useUser()
    const minPlan = computed(() => requiredPlan(props.feature))
    const allowed = computed(() => can('plan', props.feature))
    return { props, can, allowed, minPlan }
  },
})
