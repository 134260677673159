//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref, useContext } from '@nuxtjs/composition-api'
import useLayout from '@/composables/layout'
import useOnboarding from '@/composables/onboarding'
import { get as _get, has as _has } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useUser } from '@/composables/user'
export default defineComponent({
  setup() {
    const { useMutations } = createNamespacedHelpers('documents')
    const { resetState } = useMutations(['resetState'])

    /*if (_has(window, '$crisp')) {
      window.$crisp.push(['do', window.innerWidth <= 768 ? 'chat:hide' : 'chat:show'])
    }*/

    const { $bus } = useContext()
    const { user, company } = useUser()
    const layout = useLayout()
    const onboarding = useOnboarding()

    const bgClass = ref('bg-gray-50')

    const plansModal = ref(null)
    const contactSidePanel = ref(null)
    const helpSidePanel = ref(null)
    const currentContact = ref(null)

    const openContact = async (contact) => {
      currentContact.value = contact.id
      await nextTick()
      contactSidePanel.value.openModal()
    }

    const openHelp = () => helpSidePanel.value.openModal()

    const openChat = () => {
      if (_has(window, '$crisp')) {
        window.$crisp.push(['do', 'chat:show'])

        window.$crisp.push(['set', 'user:email', [user.value.email]])

        if (_get(user.value, 'phone', null)) {
          window.$crisp.push(['set', 'user:phone', [user.value.phone]])
        }

        window.$crisp.push(['set', 'user:company', company.value.name])

        if (`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`.trim() !== '') {
          window.$crisp.push([
            'set',
            'user:nickname',
            [`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`],
          ])
        }

        window.$crisp.push(['do', 'chat:open'])
      }
    }

    onMounted(() => {
      window.onbeforeunload = undefined
      resetState()
      $bus.on('openContact', (contact) => openContact(contact))
      $bus.on('openHelp', () => openHelp())
      $bus.on('openChat', () => openChat())
      $bus.on('openPlans', () => plansModal.value.openModal())
      $bus.on('closePlans', () => plansModal.value.closeModal())
    })

    onBeforeUnmount(() => {
      $bus.off('openContact')
      $bus.off('openHelp')
      $bus.off('openChat')
      $bus.off('openPlans')
      $bus.off('closePlans')
    })

    return { ...layout, ...onboarding, bgClass, currentContact, contactSidePanel, helpSidePanel, plansModal }
  },
})
