import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import { fr } from '@braid/vue-formulate-i18n'

import errorHandler from '~/utils/error-handler'

import { isValidPhoneNumber } from 'libphonenumber-js'

import FormulateVueTelInput from '@/components/FormulateVueTelInput'
import FormulateCurrencyInput from '@/components/FormulateCurrencyInput'

Vue.component('FormulateVueTelInput', FormulateVueTelInput)
Vue.component('FormulateCurrencyInput', FormulateCurrencyInput)

Vue.use(VueFormulate, {
  uploader: function (file, progress) {
    // optionally handle the `file` for your own purposes here...
    progress(100)
    return Promise.resolve({
      url: URL.createObjectURL(file),
    })
  },
  library: {
    currency: {
      classification: 'text',
      component: 'FormulateCurrencyInput',
    },
    'tel-input': {
      classification: 'text',
      component: 'FormulateVueTelInput',
    },
    phone: {
      classification: 'text',
      component: 'TelInput',
    },
  },
  rules: {
    phone: ({ value }, ...args) => {
      if (value) {
        return isValidPhoneNumber(value, args[0].toUpperCase())
      } else {
        return true
      }
    },
  },
  plugins: [fr],
  errorHandler,
})
