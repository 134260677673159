//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  onMounted,
  useContext,
  useRoute,
  useRouter,
  ref,
  reactive,
  watch,
  computed,
  inject,
} from '@nuxtjs/composition-api'

import useModal from '@/composables/modal'
import {
  PAYMENT_METHOD_TYPES,
  PAYMENT_METHOD_CARD,
  DOCUMENT_STATUS_PAID,
  PAYMENT_METHOD_DISCOUNT,
  PAYMENT_METHOD_CREDIT,
  PAYMENT_METHOD_WIRE,
  CREDIT,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_CASH,
} from '@/constants/documents'
import { handleBackendFormError, hasFormErrors } from '@/utils/error-handler'
import { get as _get } from 'lodash-es'
import { useErrorState, useLoading } from '@/composables/loading'
import { useStatus } from '@/composables/document'
import { useUser } from '@/composables/user'
import { format } from 'date-fns'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    document: Object,
  },
  setup(props) {
    const pluralType = computed(() => pluralizeType[props.document.type])
    const { $paymentsRepository, $invoicesRepository, app, $toast, $bus } = useContext()
    const { setLoading, isLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const modal = useModal()

    const { can, requiredPlan } = useUser()
    const minPlan = computed(() => requiredPlan('documents.payments'))

    const { changeStatus } = useStatus($invoicesRepository, props.document)

    const defaultForm = () => ({
      total: null,
      paymentMethod: PAYMENT_METHOD_WIRE,
      paidAt: new Date(),
      notes: null,
    })

    const defaultErrors = () => ({
      total: false,
      paymentMethod: false,
      paidAt: false,
    })

    const form = reactive(defaultForm())
    const errors = reactive(defaultErrors())
    const formErrors = reactive(defaultErrors())

    const submitFormRaw = () => {
      Object.assign(errors, defaultErrors())

      if (form.total === null) {
        errors.total = 'Veuillez indiquer un montant'
      }
    }

    const submit = async () => {
      submitFormRaw()

      if (!hasFormErrors(errors)) {
        setLoading(true, 'add_payment')
        try {
          let paidAt = format(new Date(form.paidAt), 'yyyy-MM-dd')

          const { data } = await $paymentsRepository.create({
            ...form,
            paidAt,
            total: Math.round(form.total * 100 * 100) / 100,
            invoice: props.document.id,
          })

          $bus.emit('create.item', { type: 'payment', data })
          $bus.emit('reload.events')

          const { data: invoiceData } = await $invoicesRepository.find(props.document.id)

          $bus.emit('update.item', {
            data: invoiceData,
            type: props.document.objectType,
          })

          modal.closeModal()
        } catch (err) {
          console.log(err)
          setErrorState('add_payment')
          Object.assign(errors, handleBackendFormError(app, err, 'invoices.error.payment'))
        } finally {
          setLoading(false, 'add_payment')
        }
      }
    }

    const markAsPaid = async () => {
      try {
        setLoading(true, 'mark_as_paid')
        await changeStatus(DOCUMENT_STATUS_PAID)
        $toast.show(app.i18n.t('Le règlement a bien été enregistré'))
        $bus.emit('reload.events')

        const { data: invoiceData } = await $invoicesRepository.find(props.document.id, { cdn: 1 })

        $bus.emit('update.item', {
          data: invoiceData,
          type: props.document.objectType,
        })

        modal.closeModal()
      } catch (err) {
        setErrorState('mark_as_paid')
      } finally {
        setLoading(false, 'mark_as_paid')
      }
    }

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val === true) {
          Object.assign(errors, defaultErrors())
          Object.assign(form, defaultForm())

          if (form.total === null) {
            if (props.document.legacy) {
              form.total = _get(props.document, 'total', 0) / 100
            } else {
              form.total = _get(props.document, 'amountRemaining', 0) / 100
            }
          }
        }
      }
    )

    const paymentOptions = computed(() => {
      if (props.document.type === CREDIT) {
        return []
          .concat([PAYMENT_METHOD_WIRE, PAYMENT_METHOD_CHECK, PAYMENT_METHOD_CASH])
          .reduce((obj, key) => ({ ...obj, [key]: app.i18n.t(`documents.label.paymentMethod.${key}`) }), {})
      }

      return []
        .concat(PAYMENT_METHOD_TYPES, [PAYMENT_METHOD_CREDIT, PAYMENT_METHOD_DISCOUNT])
        .reduce((obj, key) => ({ ...obj, [key]: app.i18n.t(`documents.label.paymentMethod.${key}`) }), {})
    })

    return {
      props,
      ...modal,
      pluralType,
      form,
      formErrors,
      errors,
      PAYMENT_METHOD_TYPES,
      PAYMENT_METHOD_CREDIT,
      PAYMENT_METHOD_DISCOUNT,
      minPlan,
      paymentOptions,
      submit,
      markAsPaid,
      submitFormRaw,
      isLoading,
      hasErrorState,
    }
  },
})
