export const TYPE_IN_PROGRESS = 'in_progress'
export const TYPE_SELF_EMPLOYED = 'self_employed' // ME
export const TYPE_SOLE_PROPRIETOR = 'sole_prop' // EIRL
export const TYPE_SOLE_PROPRIETOR_LIMITED = 'sole_prop_ltd' // EIRL
export const TYPE_SINGLE_MEMBER_LIMITED = 'single_member_ltd' // EURL
export const TYPE_PUBLIC_LIMITED = 'public_limited' // SA
export const TYPE_PRIVATE_LIMITED = 'private_limited' // SARL
export const TYPE_PUBLIC_UNLISTED = 'public_unlisted' // SAS
export const TYPE_SOLE_SHAREHOLDER_LIMITED = 'sole_shareholder_ltd' // SASU
export const TYPE_REAL_ESTATE_COMPANY = 'real_estate_company' // SCI
export const TYPE_SELF_REAL_ESTATE_COMPANY = 'self_real_estate_company' // LMP + LMNP
export const TYPE_COOPERATIVE = 'cooperative' // SCOP
export const TYPE_GENERAL_PARTNERSHIP = 'general_partnership' // SNC
export const TYPE_ORGANIZATION = 'organization'

export const VAT_FREQUENCY_MONTHLY = 'monthly'
export const VAT_FREQUENCY_YEARLY = 'yearly'
export const VAT_FREQUENCY_QUARTERLY = 'quarterly'

export const VAT_FREQUENCIES = [VAT_FREQUENCY_MONTHLY, VAT_FREQUENCY_YEARLY, VAT_FREQUENCY_QUARTERLY]

export const ACCOUNTING_MODE_DISABLED = 'disabled'
export const ACCOUNTING_MODE_SOLE_PROP = 'sole_prop'
export const ACCOUNTING_MODE_COMPANY = 'company'

export const GUARANTEE_BIENNIAL = 'biennial'
export const GUARANTEE_DECENNIAL = 'decennial'
export const GUARANTEE_CIVIL_LIABILITY = 'civil'
export const GUARANTEE_DECENNIAL_CIVIL_LIABILITY = 'decennial_civil'

export const GUARANTIES = [
  GUARANTEE_BIENNIAL,
  GUARANTEE_DECENNIAL,
  GUARANTEE_CIVIL_LIABILITY,
  GUARANTEE_DECENNIAL_CIVIL_LIABILITY,
]

export const CURRENCY_EURO = 'EUR'
export const CURRENCY_DOLLAR = 'USD'

export const ROUNDING_METHOD_LINES = 'lines'
export const ROUNDING_METHOD_TOTAL = 'total'

export const COMPANY_TYPES = {
  fr: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  gf: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  gp: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  re: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  mq: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  nc: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  be: {
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_COOPERATIVE,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_SELF_EMPLOYED,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  ch: {
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_SOLE_PROPRIETOR,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  lu: {
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  cm: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  ci: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  bj: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  cg: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  cd: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  dz: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  ma: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
  sn: {
    TYPE_SELF_EMPLOYED,
    TYPE_SOLE_PROPRIETOR,
    TYPE_SOLE_PROPRIETOR_LIMITED,
    TYPE_SINGLE_MEMBER_LIMITED,
    TYPE_PUBLIC_LIMITED,
    TYPE_PRIVATE_LIMITED,
    TYPE_PUBLIC_UNLISTED,
    TYPE_SOLE_SHAREHOLDER_LIMITED,
    TYPE_REAL_ESTATE_COMPANY,
    TYPE_SELF_REAL_ESTATE_COMPANY,
    TYPE_GENERAL_PARTNERSHIP,
    TYPE_ORGANIZATION,
    TYPE_IN_PROGRESS,
  },
}

export const showCompanyLegalField = {
  fr: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  gp: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  gf: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  re: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  mq: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  nc: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  be: ['type', 'shareCapital', 'vatId', 'taxCity'],
  ch: ['type', 'shareCapital', 'taxId', 'vatId'],
  lu: ['type', 'taxId', 'vatId'],
  cm: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  ci: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  bj: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  cg: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  cd: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  dz: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  ma: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
  sn: ['type', 'shareCapital', 'taxId', 'vatId', 'tradeDirectory', 'taxCity', 'mainActivityCode'],
}

export const COMPANY_HAS_SHARE_CAPITAL = {
  fr: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  gp: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  gf: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  re: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  mq: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  nc: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  be: [TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED],
  ch: [TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED],
  lu: [TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED],
  cm: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  ci: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  bj: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  cg: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  cd: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  dz: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  ma: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
  sn: [TYPE_SINGLE_MEMBER_LIMITED, TYPE_PUBLIC_LIMITED, TYPE_PRIVATE_LIMITED, TYPE_PUBLIC_UNLISTED],
}

export const vatFrequencies = {
  VAT_FREQUENCY_MONTHLY,
  VAT_FREQUENCY_YEARLY,
  VAT_FREQUENCY_QUARTERLY,
}
