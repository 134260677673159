var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.toolbarClass]},[_vm._t("overlay"),_vm._v(" "),_c('div',{staticClass:"flex items-center flex-wrap w-full relative -mt-px"},[(!_vm.toolbarClass.includes('rounded-b'))?_c('div',{staticClass:"w-full h-px absolute bg-gray-200 top-[41px] left-0"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"block sm:hidden w-full h-px absolute bg-gray-200 bottom-[40px] left-0"}),_vm._v(" "),_vm._l((_vm.toolbar),function(group,i){return _c('div',{staticClass:"flex h-10 -ml-px border-r border-gray-200 mt-px",class:{ '!border-0': i === _vm.toolbar.length - 1 }},[_vm._l((group),function(button){return [(
            !button.component &&
            (!button.isActiveArgs ||
              _vm.expand ||
              ['bold', 'italic', 'strike', 'underline'].includes(button.isActiveArgs[0]) ||
              !['xs', 'sm'].includes(_vm.$mq))
          )?_c('button',{staticClass:"editor-btn text-xs px-3 py-2",class:{
            active: button.isActiveArgs !== undefined && _vm.editor && _vm.editor.isActive.apply(_vm.editor, button['isActiveArgs']),
          },attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('editorButton', button)}}},[_c('fa-icon',{attrs:{"icon":button.icon}})],1):_vm._e(),_vm._v(" "),_c(button.component,{tag:"component",staticStyle:{"z-index":"999"},attrs:{"button":button,"editor":_vm.editor,"data":_vm.data},on:{"select":function($event){return _vm.$emit('editorAction', $event)},"toggle":function($event){return _vm.$emit('toggleComponent', $event)},"openGallery":function($event){return _vm.$emit('openGallery')}}})]})],2)}),_vm._v(" "),(['xs', 'sm'].includes(_vm.$mq))?_c('button',{staticClass:"editor-btn text-xs px-3 py-2 h-[40px]",attrs:{"type":"button"},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('fa-icon',{attrs:{"icon":['far', 'ellipsis']}})],1):_vm._e(),_vm._v(" "),_vm._t("actions")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }