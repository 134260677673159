//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { isBefore } from 'date-fns'
import { useUser } from '@/composables/user'

export default defineComponent({
  setup() {
    const { subscription, company } = useUser()
    return { subscription, company, isBefore }
  },
})
