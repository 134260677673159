//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import useHighlight from '@/composables/highlight'

import { computed, defineComponent, reactive, ref, useContext, watch, nextTick } from '@nuxtjs/composition-api'

import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { add, format, isSameDay, set } from 'date-fns'

import { useUser } from '@/composables/user'
import { useToolbar } from '@/composables/editor'
import { hasFormErrors } from '@/utils/error-handler'
import { cloneDeep as _cloneDeep, get as _get, has as _has, omit as _omit, pick as _pick } from 'lodash-es'

import { projectColors } from '@/utils/palette'
import { getUserFullName } from '@/utils/users'

const { useGetters } = createNamespacedHelpers('documents')

const defaultForm = (params) => ({
  startAt: params.startAt || new Date(),
  endAt: params.endAt || new Date(),
  timeSpecified: params.timeSpecified || false,
  startTime: params.startTime || null,
  endTime: params.endTime || null,
  name: params.name || null,
  color: params.color || '#8f94ea',
  parent: params.parent || null,
  type: params.type || 'task',
  description: params.description || null,
  assignee: params.assignee || null,
})

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'create',
    },
    project: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'task',
    },
    task: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {},
    },
    fetchId: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { highlight } = useHighlight()
    const { setLoading, isLoading } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()

    const { app, $toast, $bus, $tasksRepository } = useContext()

    const { company, subscriptionUsers } = useUser()

    const editorContent = ref(null)

    const form = ref(defaultForm({ type: props.type }))
    const formErrors = reactive({ name: false })

    const taskId = ref(null)
    const fetchError = ref(null)

    const shownHours = ref(false)

    const startHourOptions = computed(() => {
      let options = []
      for (let i = 0; i < 24; ++i) {
        for (const min of [0, 15, 30, 45]) {
          options.push(`${i.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`)
        }
      }
      return options
    })

    const endHourOptions = computed(() => {
      let options = []
      let startMinutes = 0
      let start = form.value.startTime !== null ? form.value.startTime.split(':') : null

      if (start) {
        startMinutes = Number(start[0]) * 60 + Number(start[1])
      }

      for (let i = 0; i < 24; ++i) {
        for (const min of [0, 15, 30, 45]) {
          let minutes = i * 60 + min
          let startAt = new Date(form.value.startAt)
          let endAt = new Date(form.value.endAt)
          if (!isSameDay(startAt, endAt) || startMinutes < minutes) {
            options.push(`${i.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`)
          }
        }
      }
      return options
    })

    const toggleHours = () => {
      shownHours.value = true
      form.value.timeSpecified = true
    }

    watch(
      () => form.value.timeSpecified,
      (val) => {
        if (init.value) {
          if (val) {
            if (form.value.startTime === null) {
              form.value.startTime = '08:00'
              form.value.endTime = '12:00'
            }
          } else {
            form.value.startTime = null
            form.value.endTime = null
          }
        }
      }
    )

    const { toolbar } = useToolbar([
      ['bold', 'italic', 'strike', 'underline', 'unsetAllMarks'],
      ['ul', 'ol'],
      ['clearNodes'],
    ])

    const innerProject = ref(null)
    const currentProject = computed(() => innerProject.value || props.project)

    watch(
      () => form.value.name,
      (val) => val && (formErrors.name = false)
    )

    const init = ref(false)

    watch(
      () => form.value.startTime,
      (val) => {
        if (val !== null && init.value) {
          let endTime = form.value.endTime

          const hour = val
          const hours = [...new Set([val].concat(endHourOptions.value).sort())]

          let index = hours.findIndex((el) => el === hour)

          if (index !== -1) {
            if (index <= hours.length - 1) {
              let offsetEnd = hours.length - index - 1

              // Projected end time is tomorrow
              if (offsetEnd < 4) {
                endTime = hours[4 - offsetEnd - 1]

                if (isSameDay(new Date(form.value.startAt), new Date(form.value.endAt))) {
                  form.value.endAt = add(new Date(form.value.endAt), { days: 1 })
                }
              } else {
                endTime = hours[index + 4]
              }
            } else {
              // Set projected end time to 01:00
              endTime = hours[4]
            }

            form.value.endTime = endTime
          }
        }
      }
    )

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        showSearchAssignee.value = false
        showSearchLot.value = false

        if (val === true) {
          resetForm({ type: props.type })

          taskId.value = null
          form.value.id = undefined

          if (props.mode === 'update') {
            let taskData = {}

            // Getting passed task
            if (_has(props.task, 'id')) {
              taskId.value = props.task.id
              taskData = _cloneDeep(props.task)
            }

            // Fetching task by its id
            if (props.fetchId !== null) {
              setLoading(true, `fetch_task_${props.fetchId}`)
              try {
                const { data } = await $tasksRepository.find(props.fetchId, {
                  _expand: ['project', 'assignee', 'parent'],
                })
                taskId.value = props.fetchId

                data.startTime = data.timeSpecified ? format(new Date(data.startAt), 'HH:mm') : null
                data.endTime = data.timeSpecified ? format(new Date(data.endAt), 'HH:mm') : null

                taskData = data
                innerProject.value = data.project
              } catch (err) {
                fetchError.value = err
              } finally {
                setLoading(false, `fetch_task_${props.fetchId}`)
              }
            }

            form.value = defaultForm(_cloneDeep(taskData))

            if (form.value.startTime !== null) {
              form.value.timeSpecified = true
              shownHours.value = true
            } else {
              shownHours.value = false
            }
          } else {
            shownHours.value = false
          }

          await nextTick()
          init.value = true
        } else {
          init.value = false
          // Cleaning form if closing from an existing task
          if (props.mode === 'update' && _has(props.task, 'id')) {
            shownHours.value = false
            resetForm()
          }
        }
      }
    )

    const validate = function () {
      formErrors.name = !form.value.name ? app.i18n.t(`tasks.field.name.required`) : false
    }

    const submitForm = async function () {
      if (hasFormErrors(formErrors)) {
        setErrorState('create_task')
        return
      }

      setLoading(true, 'create_task')

      try {
        let formData = {
          // Send back only form data, no object data
          ..._pick(form.value, Object.keys(defaultForm({}))),
          description: form.value.description || null,
          type: props.mode === 'update' ? form.value.type : props.type,
          project: _get(currentProject.value, 'id', null),
          assignee: _get(form.value, 'assignee.id', null),
        }

        let parent = _get(form.value.parent, 'id', _get(props.task, 'parent.id', null))
        if (parent) {
          formData.parent = parent
        }

        const _expand = props.type !== 'lot' ? ['parent', 'assignee'] : ['tasks']

        if (props.type === 'milestone') {
          formData.startAt = formData.endAt
        }

        if (props.type === 'lot') {
          formData = _pick(formData, [].concat(['project', 'name', 'description'], !taskId.value ? ['type'] : []))
        }

        if (props.type !== 'lot' && formData.timeSpecified === true) {
          let [startHour, startMinutes] = formData.startTime.split(':')
          formData.startAt = set(new Date(formData.startAt), {
            hours: Number(startHour),
            minutes: Number(startMinutes),
            seconds: 0,
          }).toISOString()

          let [endHour, endMinutes] = formData.endTime.split(':')
          formData.endAt = set(new Date(formData.endAt), {
            hours: Number(endHour),
            minutes: Number(endMinutes),
            seconds: 0,
          }).toISOString()
        }

        formData = _omit(formData, ['startTime', 'endTime', 'isCurrent'])

        const { data, metadata } = taskId.value
          ? await $tasksRepository.update(taskId.value, formData, { _expand })
          : await $tasksRepository.create(formData, { _expand })

        $toast.show(app.i18n.t(`tasks.notice.${taskId.value ? 'updated' : 'created'}.${props.type}`))

        $bus.emit(props.mode === 'create' ? 'create.item' : 'update.item', {
          type: 'task',
          metadata,
          data,
        })

        emit(props.mode === 'create' ? 'created' : 'updated', data)

        resetForm()
        modal.closeModal()
      } catch (err) {
        console.log(err)
        setErrorState('create_task')
      } finally {
        setLoading(false, 'create_task')
      }
    }

    const resetForm = () => {
      form.value = { ...defaultForm({ parent: props.parent, type: props.type }) }

      if (editorContent.value !== null) {
        editorContent.value.updateValue('')
      }
      taskId.value = null
      innerProject.value = null
    }

    watch(
      () => form.value.startAt,
      (val) => {
        if (new Date(val) > new Date(form.value.endAt)) {
          form.value.endAt = new Date(val)
        }
      }
    )

    const showSearchAssignee = ref(false)
    const showSearchLot = ref(false)

    return {
      ...modal,
      currentProject,
      editorContent,
      toolbar,
      form,
      formErrors,
      fetchError,
      isLoading,
      hasErrorState,
      company,
      projectColors,
      getUserFullName,
      shownHours,
      showSearchAssignee,
      showSearchLot,
      subscriptionUsers,
      startHourOptions,
      endHourOptions,
      toggleHours,
      highlight,
      validate,
      submitForm,
    }
  },
})
