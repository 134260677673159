//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref, nextTick } from '@nuxtjs/composition-api'
import { useCycleList, useEventListener } from '@vueuse/core'
import { get as _get } from 'lodash-es'

export default defineComponent({
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const transition = ref('slide-right')
    const { state: currentFile, next, prev, go } = useCycleList(props.files)

    async function prevImg() {
      transition.value = 'slide-right'
      await nextTick()
      prev()
    }

    async function nextImg() {
      transition.value = 'slide-left'
      await nextTick()
      next()
    }

    onMounted(() => {
      const index = props.files.findIndex((el) => _get(el, 'id') === _get(props.file, 'id', null))
      if (index !== -1) go(index)
    })

    useEventListener(document, 'keydown', ($event) => {
      if ($event.key === 'ArrowRight') nextImg()
      if ($event.key === 'ArrowLeft') prevImg()
    })

    return {
      currentFile,
      transition,
      prevImg,
      nextImg,
      _get,
    }
  },
})
