var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editor)?_c('bubble-menu',{directives:[{name:"show",rawName:"v-show",value:(!_vm._has(_vm.editor.getAttributes('link'), 'href')),expression:"!_has(editor.getAttributes('link'), 'href')"}],staticClass:"rounded-lg bg-white shadow-lg shadow-gray-300/30 border border-gray-300 p-1.5",attrs:{"editor":_vm.editor,"tippy-options":_vm.tippyOptions,"plugin-key":"bubble-2"}},[_c('div',{staticClass:"flex items-center flex-wrap w-full relative"},[_vm._l((_vm.toolbar),function(group,i){return _c('div',{staticClass:"flex -ml-px border-r border-gray-200 space-x-1.5",class:{ '!border-0': i === _vm.toolbar.length - 1, 'mr-1.5 pr-1.5': i < _vm.toolbar.length - 1 }},[_vm._l((group),function(button){return [(
            !button.component &&
            (!button.isActiveArgs ||
              _vm.expand ||
              ['bold', 'italic', 'strike', 'underline'].includes(button.isActiveArgs[0]) ||
              !['xs', 'sm'].includes(_vm.$mq))
          )?_c('button',{staticClass:"editor-btn rounded-md text-xs px-3 py-2",class:{
            active: button.isActiveArgs !== undefined && _vm.editor && _vm.editor.isActive.apply(_vm.editor, button['isActiveArgs']),
          },attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('editorButton', button)}}},[_c('fa-icon',{attrs:{"icon":button.icon}})],1):_vm._e(),_vm._v(" "),_c(button.component,{tag:"component",staticStyle:{"z-index":"999"},attrs:{"button":button,"editor":_vm.editor,"data":_vm.data,"context":"bubble"},on:{"select":function($event){return _vm.$emit('editorAction', $event)},"toggle":function($event){return _vm.$emit('toggleComponent', $event)},"openGallery":function($event){return _vm.$emit('openGallery')}}})]})],2)}),_vm._v(" "),(['xs', 'sm'].includes(_vm.$mq))?_c('button',{staticClass:"editor-btn text-xs px-3 py-2",attrs:{"type":"button"},on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('fa-icon',{attrs:{"icon":['far', 'ellipsis']}})],1):_vm._e(),_vm._v(" "),_vm._t("actions")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }