//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, ref, useContext, useFetch } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'default',
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { $contactsRepository } = useContext()
    const summary = ref(null)

    const { fetchState } = useFetch(async () => {
      try {
        const { data: summaryData } = await $contactsRepository.get(props.contact.id, 'summary')
        summary.value = summaryData
      } catch (err) {}
    })

    return { fetchState, summary }
  },
})
