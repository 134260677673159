var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"isOpen":_vm.isModalOpen(),"content-classes":"px-4 sm:px-0 pt-8 sm:pt-16 md:pt-32 mb-12","z-index-number":_vm.getZIndex(100002)},on:{"closeModal":_vm.closeModal}},[[_c('div',{staticClass:"modal-inner w-full max-w-md text-gray-700"},[_c('div',{staticClass:"p-4 sm:p-6 sm:pt-5"},[_c('h3',{staticClass:"font-medium text-lg mb-3"},[_vm._v("\n          "+_vm._s(_vm.$t(
              (_vm.pluralType + ".title.delete" + (_vm.object && _vm.object.type === 'other' ? '_other' : '') + (_vm.typeSuffix ? ("_" + _vm.typeSuffix) : ''))
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-sm mb-4 text-gray-600",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              (_vm.pluralType + ".text.delete" + (_vm.object && _vm.object.type === 'other' ? '_other' : '') + (_vm.typeSuffix ? ("_" + _vm.typeSuffix) : ''))
            )
          )}}),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"text-sm text-red-500 my-2 bg-red-50 p-3 rounded-md"},[_vm._v("\n          "+_vm._s(_vm.count ? _vm.$tc((_vm.pluralType + ".error.delete." + _vm.error), _vm.count) : _vm.$t((_vm.pluralType + ".error.delete." + _vm.error)))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('Button',{staticClass:"ml-auto mr-2",attrs:{"minimal":""},on:{"clicked":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t("layout.action.cancel")))]),_vm._v(" "),_c('Button',{ref:"deleteButton",attrs:{"color":"red","loading":_vm.isLoading('delete_item'),"error":_vm.hasErrorState('delete_item')},on:{"clicked":_vm.deleteItem}},[_vm._v(_vm._s(_vm.$t(
                (_vm.pluralType + ".action.delete" + (_vm.object && _vm.object.type === 'other' ? '_other' : '') + (_vm.typeSuffix ? ("_" + _vm.typeSuffix) : ''))
              )))])],1)])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }