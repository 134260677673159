//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import useModal from '~/composables/modal'

import { useUser } from '~/composables/user'
import { useLoading } from '~/composables/loading'

import { defineComponent, computed, reactive, ref, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { $axios, $auth, app, $bus } = useContext()
    const modal = useModal()

    const { isLoading, setLoading } = useLoading()
    const { user, updateUser } = useUser()

    const error = ref(null)

    const fields = reactive({
      username: '',
      password: '',
    })



    const handleSubmit = async function () {
      try {
        setLoading(true, 'login')
        await $auth.login({
          email: fields.username,
          password: fields.password,
        })
        $bus.emit('reconnected')
        modal.closeModal()
      } catch (err) {
        const message = _get(err, 'response.data.message', null)
        error.value = app.i18n.t(`layout.errors.${message ?? 'unknown'}`)
      } finally {
        setLoading(false, 'login')
      }
    }

    return {
      ...modal,
      isLoading,
      user,
      fields,
      error,
      handleSubmit,
    }
  },
})
