import { ref, reactive, computed, nextTick } from '@nuxtjs/composition-api'

export default function useModal(emit) {
  const modalProps = reactive({})
  const modal = ref({
    instance: 1,
    show: false,
  })

  const zIndex = ref(8889)
  const openModal = (props) => {
    let indexes = window.document.querySelectorAll('.zIndexMod')

    let newIndex = Array.from(indexes).reduce((highest, el) => {
      if (Number(el.dataset.z) > highest) {
        highest = Number(el.dataset.z)
      }
      return highest
    }, 0)

    if (newIndex > 0) {
      zIndex.value = newIndex + 1
    }

    modal.value.instance += 1
    modal.value.show = true
    Object.assign(modalProps, props)
  }

  const isModalOpen = () => modal.value.show === true

  const closeModal = async () => {
    modal.value.show = false
    await nextTick()
    if (typeof emit !== 'undefined') {
      emit('modalClosed')
    }
  }

  const getZIndex = (min = 0) => {
    if (zIndex.value < min) return min
    return zIndex.value
  }

  return { modal, modalProps, openModal, isModalOpen, closeModal, getZIndex }
}
