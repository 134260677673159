//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useHighlight from '@/composables/highlight'

export default defineComponent({
  props: { isSelected: Function, result: Object, search: String },
  setup(props) {
    const { highlight } = useHighlight()
    return { props, highlight }
  },
})
