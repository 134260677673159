import { normalizeString } from '~/utils/normalizers'

export default function useHighlight() {
  const parser = new DOMParser()

  const setHighlight = (source, search, index) =>
    `${source.slice(0, index)}<span class="bg-brand-200 text-brand-500 py-0.5">${source.slice(
      index,
      index + search.length
    )}</span>${source.slice(index + search.length)}`

  function highlight(text = '', search = '') {
    const doc = parser.parseFromString(text, 'text/html')

    if (!text) return
    if (search === null) search = ''

    let elements = doc.body.getElementsByTagName('*')

    if (elements.length) {
      for (const el of elements) {
        const index = normalizeString(el.innerText).indexOf(normalizeString(search))

        if (index !== -1) {
          let innerText = setHighlight(el.innerText, search, index)
          el.outerHTML = el.outerHTML.replace(el.innerText, innerText)
          break
        }
      }
    } else {
      const index = normalizeString(doc.body.innerText).indexOf(normalizeString(search))

      if (index !== -1) {
        doc.body.innerHTML = setHighlight(doc.body.innerText, search, index)
      }
    }

    return doc.body.innerHTML
  }

  return { highlight }
}
