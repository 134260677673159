//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
    },
    grows: {
      type: Boolean,
      default: false,
    },
    autoWidth: Boolean,
  },
  data() {
    return {}
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
  },
}
