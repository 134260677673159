//
//
//
//
//
//
//
//
//
//
//
//

import useCurrencyInput from 'vue-currency-input'
import { useUser } from '@/composables/user'
import { defineComponent, watch, computed, ref, nextTick } from '@nuxtjs/composition-api'
import { getCountryLocale } from '@/config/locales'
import { get as _get } from 'lodash-es'

export default defineComponent({
  name: 'CurrencyInput',
  props: {
    value: [String, Number, null],
    disabled: Boolean,
    updateModel: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { company } = useUser()

    const inputOptions = computed(() => ({
      currency: _get(company.value, 'currency', 'EUR'),
      locale: getCountryLocale(company.value.country),
      distractionFree: true,
      allowNegative: false,
      autoDecimalDigits: false,
      ...props.options,
    }))

    const nextValue = ref(null)

    const handleBlur = async ($event) => {
      await nextTick()
      if (nextValue.value) {
        setValue(nextValue.value)
        nextValue.value = null
      }

      emit('blur', $event)
    }

    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(inputOptions.value)

    watch(
      () => props.value,
      (value) => {
        if (_get(window, 'document.activeElement', null) !== inputRef.value) {
          setValue(value)
          nextValue.value = null
        } else {
          nextValue.value = value
        }
      }
    )

    if (props.updateModel) {
      watch(
        () => props.options,
        (options) => {
          setOptions(inputOptions.value)
        }
      )
    }

    const focusElement = async () => {
      if (inputRef.value) {
        inputRef.value.focus()
        inputRef.value.setSelectionRange(0, 999)
      }
    }

    return { props, focusElement, inputOptions, handleBlur, inputRef, formattedValue }
  },
})
