import { render, staticRenderFns } from "./News.vue?vue&type=template&id=cd2a25a2&"
import script from "./News.vue?vue&type=script&lang=js&"
export * from "./News.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Loading.vue').default,NewsDetails: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/news/NewsDetails.vue').default,NewsItem: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/news/NewsItem.vue').default})
