//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref, useContext, computed, watch } from '@nuxtjs/composition-api'
import { useErrorState, useLoading } from '@/composables/loading'
import { useInfiniteScroll } from '@/composables/infiniteScroll'
import { useUser } from '@/composables/user'
import { get as _get } from 'lodash-es'
import { watchDebounced } from '@vueuse/core'

export default defineComponent({
  props: {
    project: Object,
    form: Boolean,
  },
  setup(props, { emit }) {
    const { setLoading, isLoading } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()
    const { $toast, $projectsRepository, $companiesRepository } = useContext()
    const { company } = useUser()

    const users = ref([])

    const items = ref([])
    const search = ref(null)

    const { fetch, page, scrollContainer, handleMetadata, handleEventListener } = useInfiniteScroll(
      'project_users',
      async (replace = false) => {
        setLoading(true, `project_users`)
        try {
          const { data, metadata } = await $companiesRepository.list(
            {
              params: {
                _search: search.value,
                _page: page.value,
                _limit: 50,
                _order: 'asc',
                _sort: 'createdAt',
              },
            },
            `${company.value.id}/users`
          )

          if (replace) items.value = data
          else items.value = [].concat(items.value, data)
          handleMetadata(items.value, metadata, replace)
        } catch (err) {
        } finally {
          setLoading(false, `project_users`)
          setLoading(false, 'search_project_users')
        }
      }
    )

    watchDebounced(
      search,
      () => {
        setLoading(true, 'search_project_users')
        page.value = 1
        fetch(true)
      },
      {
        debounce: 250,
      }
    )

    onMounted(() => {
      users.value = [].concat(props.project.users)
      fetch()
    })

    watch(users, (val) => {
      if (props.form) emit('update', val)
    })

    const memberIds = computed(() =>
      users.value
        .reduce((arr, el) => {
          arr.push(_get(el, 'user.id', undefined))
          return arr
        }, [])
        .filter(Boolean)
    )

    async function update() {
      setLoading(true, `update_users`)
      try {
        if (!props.form) {
          const { data } = await $projectsRepository.update(
            props.project.id,
            {
              users: [].concat(users.value).reduce((arr, el) => {
                arr.push({ userId: el.user.id })
                return arr
              }, []),
            },
            {
              _expand: ['users', 'assignee'],
            }
          )
          $toast.show('Équipe du chantier mise à jour.')
          emit('update', [].concat(data.users))
        } else {
          emit('update', users.value)
        }

        emit('close')
      } catch (err) {
        setErrorState(`update_users`)
      } finally {
        setLoading(false, `update_users`)
      }
    }

    return { props, search, scrollContainer, users, memberIds, items, update, isLoading }
  },
})
