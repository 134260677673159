import { render, staticRenderFns } from "./SendDocumentDropdown.vue?vue&type=template&id=d9a50efc&"
import script from "./SendDocumentDropdown.vue?vue&type=script&lang=js&"
export * from "./SendDocumentDropdown.vue?vue&type=script&lang=js&"
import style0 from "./SendDocumentDropdown.vue?vue&type=style&index=0&id=d9a50efc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,DropdownItem: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/DropdownItem.vue').default})
