//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const showLoading = ref(false)

    onMounted(() => {
      if (props.delay > 0) {
        setTimeout(() => (showLoading.value = true), props.delay)
      } else {
        showLoading.value = true
      }
    })
    return { showLoading }
  },
})
