//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'

import {
  defineComponent,
  computed,
  ref,
  watch,
  useContext,
  useFetch,
  useRouter,
  useRoute,
  onMounted,
} from '@nuxtjs/composition-api'

import { pluralizeType } from '@/api/resources'

import { DOCUMENT_STATUS_OPEN, DOCUMENT_STATUS_ACCEPTED, QUOTE, INVOICE } from '@/constants/documents'
import { CONTACT_TYPE_LEAD, CONTACT_TYPE_CLIENT } from '@/constants/contacts'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: INVOICE,
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const pluralType = computed(() => pluralizeType[props.type])
    const { $getRepository, $invoicesRepository } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    onMounted(() => {
      if (_get(route.value, 'query.a', null) === 'new') {
        modal.openModal()
      }
    })

    const modal = useModal()
    const selectedTab = ref('quote')
    const search = ref(null)

    watch(
      () => modal.isModalOpen(),
      (val) => {
        selectedTab.value = props.type === INVOICE ? 'quote' : 'contact'
      }
    )

    return {
      props,
      pluralType,
      CONTACT_TYPE_CLIENT,
      INVOICE,
      ...modal,
      search,
      selectedTab,
      pluralizeType,
      isLoading,
      hasErrorState,
    }
  },
})
