import { get as _get, has as _has } from 'lodash-es'
import version from 'static/version.json'
import { CREDIT, INVOICE, QUOTE } from '@/constants/documents'
export default function ({ $axios, store, route, $toast, app, $bus, $config }) {
  $axios.interceptors.response.use(
    (res) => {
      const data = _get(res.data, 'data')

      if (_has(data, 'object')) {
        res.data.data.isQuote = () => false
        res.data.data.isInvoice = () => false
        res.data.data.isCredit = () => false

        switch (data.objectType) {
          case QUOTE:
            res.data.data.isQuote = () => true
            break
          case INVOICE:
            res.data.data.isInvoice = () => true
            break
          case CREDIT:
            res.data.data.isCredit = () => true
            break
        }
      }

      return res
    },
    (error) => {
      const status = _get(error, 'response.status', 0)
      const message = _get(error, 'response.data.message', null)
      const originalRequest = error.config

      if (_get(error, 'code') === 'ECONNABORTED') {
        throw error
      }

      if (status === 0) {
        throw { response: { error: 'unknown' } }
      }

      switch (status) {
        case 400:
          const errors = Object.entries(_get(error, 'response.data.errors', {})).reduce((arr, [k, v]) => {
            arr.push(app.i18n.t(`layout.errors.${v}`))
            return arr
          }, [])

          if (_get(originalRequest, 'errorMessage', false) !== false) {
            $toast.error(`<div>
            <span class="block font-medium">Une erreur interne est survenue</span>
            <div class="text-xs">
              ${errors.join('<br />')}
            </div>
          </div>`)
          }
          break
        case 401:
          $bus.emit('disconnected')
          break
        case 409:
          $bus.emit('idempotencyError', message)
          break
        case 500:
          /*$sentry.setExtra('error', {
            message: _get(error, 'message', null),
            path: _get(app, 'router.history.current.fullPath', null),
            query: JSON.stringify(_get(app, 'router.history.current.query', {})),
          })
          $sentry.captureException(error)*/

          if (_get(originalRequest, 'errorMessage', null) !== false) {
            $toast.error(`<div>
            <span class="block font-medium">Une erreur interne est survenue</span>
            <span>Veuillez réessayer ultérieurement ou contacter notre équipe</span>
          </div>`)
            break
          }
      }

      throw error
    }
  )

  $axios.onRequest((config) => {
    let impersonate = _get(store, 'state.impersonate', null)

    if (impersonate === null) {
      impersonate = _get(route, 'query._impersonate', null)
    }

    if (impersonate !== null) {
      $axios.setHeader('X-Switch-User', impersonate.replace(' ', '+'))
    }

    let companyId = _get(store, '$auth.company.value.id')

    if (companyId) {
      $axios.setHeader('X-Costructor-Company', companyId)
    }
  })

  $axios.onResponse((res) => {
    try {
      const targetVersion = _get(res, 'headers.x-costructor-app-version', null)
      store.commit('setMustUpgrade', { targetVersion, appVersion: version.version })
    } catch (err) {
      console.log(err)
    }

    const success = _get(res, 'config.successMessage', null)
    if (success !== null) $toast.show(success)
  })
}
