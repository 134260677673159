import { get as _get } from 'lodash-es'

const colors = [
  '#9EB384',
  '#F6635C',
  '#C23373',
  '#279EFF',
  '#94ADD7',
  '#7895CB',
  '#088395',
  '#569DAA',
  '#A4BC92',
  '#159895',
  '#1A5F7A',
  '#9E4784',
  '#28CC9E',
  '#F2921D',
  '#645CBB',
  '#227C70',
  '#4B56D2',
  '#439A97',
]

export default function () {
  function hashCode(str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  const pickColor = (str) => {
    const index = Math.round(Math.abs(hashCode(str) % 360) / 20)
    return _get(colors, index, '#4B56D2')
  }

  return { pickColor }
}
