var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"shrink-0 inline-flex items-center justify-center font-medium rounded-full uppercase",class:( _obj = {
    'w-4 h-4 text-[10px]': _vm.xs,
    'w-8 h-8 text-sm': _vm.xl,
    'w-6 h-6 text-xs': !_vm.xs && !_vm.xl
  }, _obj[_vm.roleColors[_vm.role]] = _vm.role, _obj[_vm.color] = _vm.color, _obj ),style:({
    backgroundColor: _vm.name && _vm.autocolor ? _vm.pickColor(_vm.name) : undefined,
  })},[_vm._v(_vm._s(_vm.initials || ' '))])}
var staticRenderFns = []

export { render, staticRenderFns }