//
//
//

import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { $bus } = useContext()

    const toggleSidebar = () => {
      $bus.emit('toggleSidebar')
    }

    return { toggleSidebar }
  },
})
