//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has as _has, get as _get } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext, ref, reactive, watch } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    category: Object,
    current: Object,
    name: String,
  },
  setup(props, { emit }) {
    const modal = useModal()

    const { $productCategoriesRepository, $bus, $toast, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const id = ref(null)
    const form = reactive({
      name: '',
    })

    const error = ref(null)

    const submit = async () => {
      if (id.value) {
        try {
          setLoading(true, `update_${id.value}`)
          const { data } = await $productCategoriesRepository.update(id.value, {
            ...form,
          })
          emit(`updated`, data)
          modal.closeModal()
        } catch (err) {
          error.value = _get(err.response, 'data.data.error', null)
          setErrorState(`update_${id.value}`)
        } finally {
          setLoading(false, `update_${id.value}`)
        }
      } else {
        if (!isLoading('create_item')) {
          try {
            setLoading(true, 'create_item')
            const { data } = await $productCategoriesRepository.create({
              ...form,
              parent: _get(props, 'category.id', null),
            })
            emit(`created`, data)
            modal.closeModal()
          } catch (err) {
            error.value = _get(err.response, 'data.data.error', null)
            setErrorState('create_item')
          } finally {
            setLoading(false, 'create_item')
          }
        }
      }
    }

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val) {
          if (props.current) {
            id.value = props.current.id
            form.name = props.current.name
          } else {
            id.value = null
            form.name = props.name || ''
          }
        }
      }
    )

    return {
      props,
      id,
      ...modal,
      error,
      form,
      isLoading,
      hasErrorState,
      submit,
    }
  },
})
