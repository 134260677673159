//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, computed } from '@nuxtjs/composition-api'
import { get as _get } from 'lodash-es'
export default defineComponent({
  props: {
    error: {
      type: Error,
      default: () => {},
    },
    resource: String,
    minimal: Boolean,
    id: String,
    to: String,
  },
  setup(props) {
    const { app } = useContext()

    const status = _get(props.error, 'response.status', null)

    const message = computed(() => {
      const errorMessage = _get(props.error, 'response.data.message', null)

      return app.i18n.te(`${props.resource || 'layout'}.error.${status || 'unknown'}`)
        ? app.i18n.t(`${props.resource || 'layout'}.error.${status || 'unknown'}`, { id: props.id })
        : errorMessage
    })

    return { props, message, status }
  },
})
