//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { has as _has } from 'lodash-es'
import Teleport from 'vue2-teleport'

export default defineComponent({
  components: {
    Teleport,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { app, $bus } = useContext()

    const modal = useModal()
    const feedbackModal = ref(null)

    const openChat = () => {
      $bus.emit('openChat')
      modal.closeModal()
    }

    const openHelp = () => {
      if (_has(window, '$crisp')) {
        window.$crisp.push(['do', 'helpdesk:search'])
      }
    }

    return {
      ...modal,
      feedbackModal,
      props,
      openChat,
      openHelp,
    }
  },
})
