//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has as _has } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext, ref, useRoute } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const modal = useModal()

    const { app, $feedbacksRepository } = useContext()
    const route = useRoute()

    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const field = ref('')
    const sent = ref(false)

    const submit = async () => {
      setLoading(true, 'send_feedback')
      try {
        await $feedbacksRepository.create(
          {
            text: field.value,
            page: route.value.path,
          },
          { progress: false }
        )

        sent.value = true
      } catch (err) {
        setErrorState('send_feedback')
      } finally {
        setLoading(false, 'send_feedback')
      }
    }

    return {
      props,
      ...modal,
      field,
      sent,
      isLoading,
      hasErrorState,
      submit,
    }
  },
})
