export const CONTACT_TYPE_LEAD = 'lead'
export const CONTACT_TYPE_CLIENT = 'client'
export const CONTACT_TYPE_SUPPLIER = 'supplier'
export const CONTACT_TYPE_SUBCONTRACTOR = 'subcontractor'
export const CONTACT_TYPE_EMPLOYEE = 'employee'

export const CONTACT_LEGAL_STATUS_INDIVIDUAL = 'individual'
export const CONTACT_LEGAL_STATUS_COMPANY = 'company'

export const CONTACT_TYPES = [CONTACT_TYPE_LEAD, CONTACT_TYPE_CLIENT, CONTACT_TYPE_SUBCONTRACTOR, CONTACT_TYPE_SUPPLIER]
export const CLIENT_TYPES = [CONTACT_TYPE_LEAD, CONTACT_TYPE_CLIENT]
export const BUSINESS_TYPES = [CONTACT_TYPE_SUBCONTRACTOR, CONTACT_TYPE_SUPPLIER]

export const getContactColors = {
  [CONTACT_TYPE_LEAD]: 'bg-yellow-100 text-yellow-500',
  [CONTACT_TYPE_CLIENT]: 'bg-green-100 text-green-500',
  [CONTACT_TYPE_SUPPLIER]: 'bg-brand-100 text-brand-500',
  [CONTACT_TYPE_SUBCONTRACTOR]: 'bg-blue-100 text-blue-500',
}
