import semver from 'semver'
export const state = () => ({
  init: false,
  settings: {},
  locales: ['en', 'fr'],
  locale: 'fr',
  impersonate: null,
  targetVersion: null,
  mustUpgrade: false,
})

export const mutations = {
  setLang(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  impersonate(state, user) {
    state.impersonate = user
  },
  setMustUpgrade(state, params) {
    try {
      if (semver.gt(params.targetVersion, params.appVersion)) {
        state.targetVersion = params.targetVersion
        state.mustUpgrade = true
      } else {
        state.mustUpgrade = false
      }
    } catch (err) {}
  },
}

export const getters = {}
