//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'

export default defineComponent({
  props: nodeViewProps,
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
})
