//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has as _has, get as _get } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { defineComponent, useContext, ref } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    typeSuffix: {
      type: String,
      default: '',
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const modal = useModal()

    const { $getRepository, $bus, $toast, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const deleteButton = ref(null)
    const error = ref(null)
    const count = ref(null)

    const pluralType =
      pluralizeType[props.object ? props.object.objectType || props.object.object || props.type : props.type]

    const repositoryKey = _has(props.object, 'object')
      ? props.object || props.object.objectType
      : pluralizeType[props.type]

    const deleteItem = async () => {
      count.value = null
      emit('delete')

      try {
        setLoading(true, 'delete_item')

        const { data } = await $getRepository(repositoryKey).delete(props.object.id)

        if (data.success === true || data.deleted === true) {
          await modal.closeModal()
          // WANING Total Ghetto type "other", will make it until v3
          $toast.show(
            app.i18n.t(`${pluralType}.notice.deleted${props.object && props.object.type === 'other' ? '_other' : ''}`)
          )
          $bus.emit(`delete.item`, { object: props.object, type: props.type, data })
          emit('deleted')
        } else {
          error.value = data.error || 'unknown'
        }
      } catch (err) {
        error.value = _get(err.response, 'data.data.error', null)
        count.value = _get(err.response, 'data.data.count', null)
        setErrorState('delete_item')
      } finally {
        setLoading(false, 'delete_item')
      }
    }

    return {
      props,
      ...modal,
      isLoading,
      hasErrorState,
      error,
      count,
      deleteButton,
      pluralType,
      deleteItem,
    }
  },
})
