//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject, nextTick, ref } from '@nuxtjs/composition-api'

import {
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CHECK,
  PAYMENT_METHOD_WIRE,
  PAYMENT_METHOD_CREDIT,
  PAYMENT_METHOD_DISCOUNT,
} from '@/constants/documents'
export default defineComponent({
  props: {
    payments: Array,
  },
  setup(props) {
    const deletePaymentModal = ref(null)
    const currentPayment = ref(null)

    const deletePayment = async (payment) => {
      currentPayment.value = payment
      await nextTick
      deletePaymentModal.value.openModal()
    }

    return {
      props,
      deletePaymentModal,
      currentPayment,
      deletePayment,
    }
  },
})
