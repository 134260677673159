//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { nanoid } from 'nanoid/non-secure'
import {useDocumentOptions} from "@/composables/document";
import {computed, defineComponent, nextTick, onMounted, ref, watch, inject} from '@nuxtjs/composition-api'
import { get as _get, has as _has, cloneDeep as _cloneDeep } from 'lodash-es'
import { useUser } from '@/composables/user'
import { useWorks } from '@/composables/products'
import {cloneUnobserved, stripHTML} from '@/utils/normalizers'

import {
  ORDER_FORM,
  AMOUNT_TYPE_PERCENT,
  AMOUNT_TYPE_SUBTOTAL,
  BUILDER,
  DOCUMENT_OPTION_DISPLAY_REFERENCE
} from "@/constants/documents";
import draggable from 'vuedraggable'
import {makeStoreReadonlyProperties} from "@/utils/reactivity";
import {BUILDER_PRODUCT_TYPES, PRODUCT_TYPE_WORK, PRODUCT_TYPE_WORK_DETAILED} from "@/constants/products";
import {createNamespacedHelpers} from "vuex-composition-helpers";
import {roundToTwo} from "@/utils/numbers";

const { useMutations, useState } = createNamespacedHelpers('documents')

export default defineComponent({
  props: {
    hasPadding: {
      type: Boolean,
      default: false,
    },
    unit: String,
    line: Object,
    sellPriceForced: Boolean,
    items: {
      type: Array,
      default: () => ([]),
    },
    type: String,
    context: String,
  },
  components: {
    draggable,
  },
  setup(props, { emit }) {
    const { units, company } = useUser()
    const { defaultWorkItem } = useWorks()

    const { object } = useState(['object'])

    const { hasOption } = useDocumentOptions()

    const showMargins = ref(company.value.builderBuyPrice)
    const showReferences = ref(hasOption(BUILDER, DOCUMENT_OPTION_DISPLAY_REFERENCE))

    const columnClasses = computed(() => {
      return {
        name: object.value === ORDER_FORM ? showReferences.value ? 'col-span-16' : 'col-span-18' : showReferences.value ? (showMargins.value ? 'col-span-9' : 'col-span-11') : (showMargins.value ? 'col-span-11' : 'col-span-12'),
        reference: 'col-span-2',
        unit: 'col-span-2',
        margin: 'col-span-2',
        quantity: 'col-span-2',
        sellPrice: showMargins.value ? 'col-span-2' : 'col-span-3',
        buyPrice: 'col-span-2',
        subtotal: showMargins.value ? 'col-span-3' : 'col-span-4',
      }
    })

    const elements = ref([].concat(props.items))

    const hasExistingElements = computed(() => elements.value.filter(el => el.isNew === false).length > 0)

    const activeRow = ref(null)

    const currentUnit = computed(() =>
      units
        .reduce((arr, unit) => [...arr, ...[{ value: unit.id, label: `${unit.symbol}` }]], [])
        .find((el) => el.value === props.unit) ?? []
    )

    const emitUpdate = function (override_elements = null) {
      emit('update', [].concat(override_elements || elements.value))
    }

    const removeWorkItem = function (index, els) {
      let override = _cloneDeep(elements.value)
      override.splice(index, 1)
      emitUpdate(override)
    }

    const setItemData = (index, prop, val) => {
      elements.value[index][prop] = val
      emitUpdate()
    }

    const workItemInput = function (index, value) {
      elements.value[index].element.name = value
      if (stripHTML(value) === '') {
        if (_has(elements.value, index + 1) && _get(elements.value[index + 1], 'element.name', '') === '') {
          elements.value.splice(index + 1, 1)
        }
      }

      emitUpdate()
    }

    watch(
      () => props.items,
      (val) => {
        elements.value = [].concat(val)
        if (elements.value.length === 0) {
          elements.value.push(defaultWorkItem())
        }
      }
    )

    const addElement = () => {
      elements.value.push(defaultWorkItem())
    }

    const handleMove = (params) => {
      let item = _get(elements.value, params.index, null)
      if (item) {
        elements.value.splice(params.index, 1)
        if (params.direction === 'up') elements.value.splice(params.index - 1, 0, item)
        if (params.direction === 'down') elements.value.splice(params.index + 1, 0, item)
      }
      emitUpdate()
    }

    const endDrag = () => {
      dragging.value = false
      emitUpdate()
    }

    const workItemSelect = (index, $event) => {
      elements.value.splice(index, 1, {
        element: {
          name: $event.name,
          reference: $event.reference,
          buyPrice: $event.buyPrice,
          sellPrice: $event.sellPrice,
          planItem: _get($event, 'planItem.id', null),
          id: $event.id,
          unit: $event.unit,
          type: $event.type,
        },
        key: nanoid(),
        id: null,
        quantity: 1,
        isNew: false,
      })


      emitUpdate()
    }

    const nextWorkItem = function (index) {
      let next = _get(this, `$refs.work_item_${index + 1}[0]`)
      if (next) next.focus()
    }

    const sellPrices = computed(() => {
      return elements.value.filter(el => el.element && el.element.name).reduce((total, item) => {
        if (item.element && item.element.sellPrice) {
          total += Math.round(item.element.sellPrice * item.quantity * 100) / 100
        }
        return total
      }, 0)
    })

    const buyPrices = computed(() => {
      return elements.value.filter(el => el.element && el.element.name).reduce((total, item) => {
        if (item.element && item.element.buyPrice) {
          total += Math.round(item.element.buyPrice * item.quantity * 100) / 100
        }
        return total
      }, 0)
    })

    watch(sellPrices, (val) => {
      //if (!props.sellPriceForced) {
        emit('updateSellPrice', val)
      //}
    })

    watch(buyPrices, (val) => {
        emit('updateBuyPrice', val)
    })

    onMounted(() => {
      if (buyPrices.value) {
        emit('updateBuyPrice', buyPrices.value)
      }
    })

    const dragging = ref(false)



    const marginRateInput = ref(null)
    const showMarginAdjust = ref(false)
    const marginAdjustTab = ref('margin')

    const { currency } = makeStoreReadonlyProperties('documents', ['currency'])

    const applyMarginRate = (params) => {

      let lines = _cloneDeep(elements.value)
      lines.forEach((line) => {
        if (
          !line.lockSellPrice &&
          (params.marginType === 'all' ||
            params.marginType === line.element.type
            )
        ) {
          const buyPrice = _get(line, 'element.buyPrice', 0)
          if (buyPrice > 0) {
            const grossMargin = (buyPrice * params.marginRate) / 100
            line.element.sellPrice = roundToTwo(buyPrice + grossMargin)
          }
        }
      })

      elements.value = _cloneDeep(lines)
      emitUpdate()

    }

    const applyAdjust = (params) => {

      let lines = _cloneDeep(elements.value)
      lines.forEach((line) => {
        if (!line.lockSellPrice) {
          const sellPrice = _get(line, 'element.sellPrice', 0)
          const adjust = Math.round(sellPrice * params.adjustAmount) / 100
          if (sellPrice > 0) {
            if (params.adjustDirection === 'plus') {
              line.element.sellPrice = roundToTwo(sellPrice + adjust)
            } else {
              line.element.sellPrice = roundToTwo(sellPrice - adjust)
            }
          }
        }
      })

      elements.value = _cloneDeep(lines)
      emitUpdate()

    }

    const marginRate = ref(_get(company.value, 'defaultMargin', null))
    const marginType = ref('all')

    const adjustAmount = ref(10)
    const adjustAmountType = ref(AMOUNT_TYPE_PERCENT)
    const adjustDirection = ref('plus')

    watch(marginType, (val) => {
      let defaultMarginRate = _get(company.value, 'defaultMargin', null)
      if (val === 'all') {
        marginRate.value = defaultMarginRate
      } else {
        if (val === PRODUCT_TYPE_WORK_DETAILED) {
          val = PRODUCT_TYPE_WORK
        }
        marginRate.value = _get(company.value, `defaultMargins.${val}`, defaultMarginRate)
      }
    })

    watch(showMarginAdjust, async (val) => {
      await nextTick()
      if (val === true && marginRateInput.value !== null) {
        marginRateInput.value.focus()
      }
    })

    const workItemModal = ref(null)
    const currentItem = ref(null)

    const editType = async (element) => {
      currentItem.value = element
      await nextTick()
      if (workItemModal.value) workItemModal.value.openModal()
    }

    const updateItem = (item) => {
      let _elements = _cloneDeep(elements.value)
      let current = _elements.find(el => el.key === item.key)

      current.element = item.element
      elements.value = _cloneDeep(_elements)

      emit('update', [].concat(elements.value))
    }

    return {
      props,
      elements,
      hasExistingElements,
      columnClasses,
      activeRow,
      dragging,
      showMargins,
      showReferences,
      object,
      endDrag,
      emitUpdate,
      setItemData,
      removeWorkItem,
      workItemInput,
      workItemSelect,
      nextWorkItem,
      addElement,
      handleMove,
      sellPrices,
      units,
      currentUnit,
      marginRateInput,
      showMarginAdjust,
      marginAdjustTab,
      currency,
      marginType,
      marginRate,
      applyMarginRate,
      applyAdjust,
      adjustAmount,
      adjustAmountType,
      adjustDirection,
      AMOUNT_TYPE_SUBTOTAL,
      PRODUCT_TYPE_WORK_DETAILED,
      BUILDER_PRODUCT_TYPES,
      ORDER_FORM,
      workItemModal,
      currentItem,
      updateItem,
      editType
    }
  },
})
