export const state = () => ({
  loaders: [],
  errors: [],
})

export const mutations = {
  setLoader(state, { key, val }) {
    const index = state.loaders.findIndex((el) => el === key)
    if (val && index === -1) {
      state.loaders.push(key)
    } else if (index !== -1) {
      state.loaders.splice(index, 1)
    }
  },
  setError(state, { key, val }) {
    const index = state.errors.findIndex((el) => el === key)
    if (val && index === -1) {
      state.errors.push(key)
    } else if (index !== -1) {
      state.errors.splice(index, 1)
    }
  },
}

export const getters = {}
