import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1536,
    '2xl': Infinity,
  },
  defaultBreakpoint: 'sm', // customize this for SSR
})
