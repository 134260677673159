import { render, staticRenderFns } from "./FileModalGallery.vue?vue&type=template&id=56048bb1&"
import script from "./FileModalGallery.vue?vue&type=script&lang=js&"
export * from "./FileModalGallery.vue?vue&type=script&lang=js&"
import style0 from "./FileModalGallery.vue?vue&type=style&index=0&id=56048bb1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default})
