//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, ref, onMounted, watch } from '@nuxtjs/composition-api'

import { useErrorState, useLoading } from '@/composables/loading'
import { pluralizeType } from '@/api/resources'
import { has as _has, get as _get } from 'lodash-es'
import { useToolbar } from '@/composables/editor'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { $getRepository, app } = useContext()
    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const repositoryKey = _has(props.object, 'object') ? props.object : pluralizeType[props.type]

    const editorContent = ref(null)
    const notes = ref(null)
    const dirty = ref(false)
    const saving = ref(false)

    onMounted(() => {
      if (editorContent.value !== null) {
        editorContent.value.updateValue(_get(props.object, 'notes', null) || '')
      }
    })

    const { toolbar } = useToolbar([['bold', 'italic', 'underline', 'unsetAllMarks'], ['ul', 'ol'], ['clearNodes']])

    async function updateNotes() {
      try {
        setLoading(true, `update_notes_${props.object.id}`)
        const { data } = await $getRepository(repositoryKey).put(props.object.id, 'notes', { notes: notes.value })
        emit('update', data)
        dirty.value = false
      } catch (err) {
        setErrorState(`update_notes_${props.object.id}`)
      } finally {
        setLoading(false, `update_notes_${props.object.id}`)
      }
    }

    const focus = () => {
      if (editorContent.value !== null) {
        editorContent.value.focusEditor()
      }
    }

    watch(
      () => props.object.notes,
      (val) => {
        editorContent.value.updateValue(_get(props.object, 'notes', null) || '')
      }
    )

    watch(notes, (newVal, oldVal) => {
      dirty.value = true
    })

    return { repositoryKey, editorContent, notes, focus, toolbar, dirty, updateNotes, isLoading, hasErrorState }
  },
})
