//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    sections: Array,
  },
  setup(props) {
    return { props }
  },
})
