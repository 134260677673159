//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has as _has, get as _get, cloneDeep as _cloneDeep } from 'lodash-es'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import useEvents from '@/composables/events'
import { transparentize } from 'khroma'
import { defineComponent, watch, ref, inject, computed, nextTick } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Slider,
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { isLoading } = useLoading()
    const { hasErrorState } = useErrorState()

    const { pickColor } = useEvents()

    const futureProjects = inject('futureProjects')
    const projects = ref([])
    const invoice = inject('invoice')

    const updatePercent = (project, percent) => {
      project.amount = (invoice.total * percent) / 100
    }

    const updateAmount = (project, amount) => {
      project.weight = (amount / invoice.total) * 100
    }

    const correctQuantityPercent = () => {}

    watch(
      () => modal.isModalOpen(),
      (val) => {
        if (val) {
          projects.value = _cloneDeep(futureProjects.value).reduce((arr, project) => {
            project.amount = (invoice.total * project.weight) / 100
            arr.push(project)
            return arr
          }, [])
        }
      }
    )

    const allocatedPercent = computed(() =>
      projects.value.reduce((amount, project) => {
        amount += project.weight
        return amount
      }, 0)
    )

    const availablePercent = computed(() => 100 - allocatedPercent.value)

    const allocatedAmount = computed(() =>
      projects.value.reduce((amount, project) => {
        amount += project.amount
        return amount
      }, 0)
    )

    const availableProgress = computed(() => invoice.total - allocatedAmount.value)

    const cancel = async () => {
      futureProjects.value = []
      await nextTick()
      modal.closeModal()
    }

    const updateProjects = () => {
      emit('update', _cloneDeep(projects.value))
      modal.closeModal()
    }

    return {
      props,
      ...modal,
      projects,
      invoice,
      allocatedPercent,
      allocatedAmount,
      availablePercent,
      availableProgress,
      transparentize,
      cancel,
      updateProjects,
      updateAmount,
      pickColor,
      isLoading,
      hasErrorState,
      updatePercent,
      correctQuantityPercent,
    }
  },
})
