//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import { computed, inject, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    context: {
      type: String,
      default: 'default',
    },
    allowCreate: Boolean,
    type: {
      type: String,
      default: 'quotes',
    },
    resourceType: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const image = computed(() =>
      _get(
        {
          invoices: 'invoice-search.svg',
          supplier_invoices: 'invoice-search.svg',
          order_forms: 'box-load.svg',
          supplier_credits: 'invoice-search.svg',
          quotes: 'invoice-calculating.svg',
          credits: 'invoice-1.svg',
          incomes: 'invoice-1.svg',
          holdbacks: 'bank-building-2.svg',
          bank_items: 'bank-building-2.svg',
          payments: 'money-check-1.svg',
          transactions: 'bank-building-2.svg',
          projects: 'wall-construction.svg',
          contacts: 'contact-book.svg',
          users: 'user-badge.svg',
        },
        props.type,
        'wall-construction.svg'
      )
    )

    const textClass = computed(() =>
      _get(
        {
          holdbacks: '-translate-y-6',
        },
        props.type,
        null
      )
    )

    return { props, image, textClass }
  },
})
