//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get } from 'lodash-es'
import {
  defineComponent,
  nextTick,
  ref,
  computed,
  useContext,
  watch,
  onMounted,
  onUpdated,
  useRoute,
} from '@nuxtjs/composition-api'
import { getRandomString } from '@/utils/documents'
import useModal from '@/composables/modal'
import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'global',
    },
  },
  setup(props) {
    const route = useRoute()

    const { $companiesRepository } = useContext()
    const { company, updateCompany } = useUser()

    const modal = useModal()

    const transitionName = ref('slide-left')
    const isTransiting = ref(false)

    const overflowClass = computed(() =>
      isTransiting.value ? 'overflow-x-hidden overflow-y-visible' : 'overflow-visible'
    )

    const step = ref(1)
    const steps = computed(
      () =>
        ({
          global: {
            1: 'OnboardingGlobalStep1',
            2: 'OnboardingGlobalStep2',
            3: 'OnboardingGlobalStep3',
          },
          documents: {
            1: 'OnboardingDocumentNumbering',
          },
        }[props.type])
    )

    const stepKey = ref(getRandomString(4))

    const stepsCount = computed(() => Object.keys(steps.value).length)
    const currentStep = computed(() => _get(company.value, `onboarding.${props.type}`, 1))

    const onboardingComponent = computed(() => steps.value[step.value])
    const mustOnboard = computed(() => {
      return currentStep.value && currentStep.value <= stepsCount.value
    })

    const handleBeforeEnter = () => {
      /*if (step.value !== 1) {
        isTransiting.value = true
      }*/
    }

    const goto = async function (nextStep) {
      isTransiting.value = true
      transitionName.value = step.value < nextStep ? 'slide-right' : 'slide-left'
      stepKey.value = getRandomString(4)
      await nextTick()
      if (step.value !== nextStep) {
        step.value = nextStep
      }
    }

    const firstStep = async function () {
      isTransiting.value = true
      transitionName.value = 'slide-left'
      stepKey.value = getRandomString(4)
      await nextTick()
      if (step.value > 1) {
        step.value = 1
      }
    }

    const prevStep = async function () {
      isTransiting.value = true
      transitionName.value = 'slide-left'
      stepKey.value = getRandomString(4)
      await nextTick()
      if (step.value > 1) {
        step.value = step.value - 1
      }
    }

    const nextStep = async function () {
      isTransiting.value = true
      transitionName.value = 'slide-right'
      stepKey.value = getRandomString(4)
      await nextTick()
      if (step.value < stepsCount.value) {
        step.value = step.value + 1
      }
    }

    const triggerOnboarding = () => {
      if (currentStep.value) {
        step.value = currentStep.value
      }

      if (mustOnboard.value) {
        modal.openModal()
      }
    }

    onMounted(() => triggerOnboarding())

    watch(
      () => route.value,
      (val) => triggerOnboarding()
    )

    watch(
      () => step.value,
      async (val) => {
        // Dont update if already completed last step
        const onboardingStep = _get(company.value, 'onboarding.global', 1)
        if (val > 1 && onboardingStep < 4) {
          try {
            updateCompany({ onboarding: { [props.type]: val } })
            await $companiesRepository.update(company.value.id, { onboarding: { [props.type]: val } })
          } catch (err) {}
        }
      }
    )

    return {
      ...modal,
      step,
      steps,
      stepsCount,
      stepKey,
      transitionName,
      isTransiting,
      onboardingComponent,
      overflowClass,
      handleBeforeEnter,
      triggerOnboarding,
      prevStep,
      nextStep,
      firstStep,
      goto,
    }
  },
})
