import { render, staticRenderFns } from "./DocumentDetailsPayments.vue?vue&type=template&id=59a13430&"
import script from "./DocumentDetailsPayments.vue?vue&type=script&lang=js&"
export * from "./DocumentDetailsPayments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaymentBadge: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/PaymentBadge.vue').default,Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,DeleteModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/DeleteModal.vue').default})
